import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Conference } from './conference.model';
import { Activate, AddToken, ConferenceActions, ConferenceActionTypes } from './conference.actions';
import { CONFERENCE_ACTIVATE, ConferenceActivateAction } from '../../conference/store/conference.action';

export const conferencesFeatureKey = 'conferencesvtwo';

export interface ConferenceState extends EntityState<Conference> {
    activeId: number | null;
    token: string | null;
}

export const adapter: EntityAdapter<Conference> = createEntityAdapter<Conference>();

export const initialState: ConferenceState = adapter.getInitialState({
    activeId: null,
    token: null
});

export function reducer(
    state = initialState,
    action: ConferenceActions
): ConferenceState {
    switch (action.type) {
        case ConferenceActionTypes.AddConference: {
            return adapter.addOne(action.payload.conference, state);
        }

        case ConferenceActionTypes.UpsertConference: {
            return adapter.upsertOne(action.payload.conference, state);
        }

        case ConferenceActionTypes.AddConferences: {
            return adapter.addMany(action.payload.conferences, state);
        }

        case ConferenceActionTypes.UpsertConferences: {
            return adapter.upsertMany(action.payload.conferences, state);
        }

        case ConferenceActionTypes.UpdateConference: {
            return adapter.updateOne(action.payload.conference, state);
        }

        case ConferenceActionTypes.UpdateConferences: {
            return adapter.updateMany(action.payload.conferences, state);
        }

        case ConferenceActionTypes.DeleteConference: {
            return adapter.removeOne(action.payload.id, state);
        }

        case ConferenceActionTypes.DeleteConferences: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case ConferenceActionTypes.LoadConferences: {
            return adapter.setAll(action.payload.conferences, state);
        }

        case ConferenceActionTypes.ClearConferences: {
            return adapter.removeAll(state);
        }

        case ConferenceActionTypes.AddToken: {
            const activateAction = <AddToken>(action);
            return {...state, token: activateAction.payload.token};
        }

        case ConferenceActionTypes.Activate: {
            const activateAction = <Activate>(action);
            return {...state, activeId: activateAction.payload.id};
        }
        default: {
            return state;
        }
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
