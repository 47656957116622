import {Conference, conferenceAdapter, EntityStateConference} from './conference.entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MemoizedSelector} from '@ngrx/store/src/selector';

export const selectConferenceState = createFeatureSelector<EntityStateConference>('conference');

export const {
    selectIds: idList,
    selectEntities: entityConferenceList,
    selectAll: selectAllConferences
} = conferenceAdapter.getSelectors(selectConferenceState);

export function getConferenceByIdSelector(id: number): MemoizedSelector<EntityStateConference, Conference> {
    const conferenceIdState = (state: EntityStateConference) => state.entities[id];

    const conferenceIdSelector = createSelector(selectConferenceState, conferenceIdState);
    return conferenceIdSelector;
}

export function getActiveConferenceSelector(): MemoizedSelector<EntityStateConference, Conference> {
    const conferenceIdState = (state: EntityStateConference) => state.activeId;

    const conferenceIdSelector = createSelector(selectConferenceState, conferenceIdState);

    const activeConferenceSelector = createSelector(
        entityConferenceList,
        conferenceIdSelector,
        (allEntities, selected) => allEntities[selected]
    );

    return activeConferenceSelector;
}
