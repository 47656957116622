import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface MultiUserAppointment {
    id: number;
    conferenceId: number;
    profileIds: number[];
    startDateTime: string;
    endDateTime: string;
    topic: string;
    tan?: string;
    period?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    appointmentMessage?: string | null;
    appointmentType?: number;
    appointmentStatus?: string | number;
}

export interface Pagination {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
}

export interface MultiUserAppointmentResponse {
    items: MultiUserAppointment[];
    pagination: Pagination;
    status: string;
}

export interface MultiUserAppointmentRequestParams {
    pageNumber?: string;
    pageSize?: string;
    orderByStartDate?: string;
    minStartDateTime?: string;
    maxStartDateTime?: string;
    appointmentType?: string | string[];
    appointmentStatus?: string | string[];
}

export enum MultiUserAppointmentRequestType {
    ALL = 'all',
    HISTORY = 'history',
    UPCOMING = 'upcoming',
    CANCELED = 'canceled'
}

export enum OrderByStartDate {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}

export const multiUserAppointmentAdapter = createEntityAdapter<MultiUserAppointment>();

export interface EntityStateMultiUserAppointment extends EntityState<MultiUserAppointment> {
    ids: string[];
    entities: { [id: string]: MultiUserAppointment };
    isInit: boolean | null;
    tanAppointmentId: string | null;
    pagination: Pagination;
}

const myDefaultMultiUserAppointmentList: EntityStateMultiUserAppointment = {
    isInit: true,
    tanAppointmentId: null,
    ids: [],
    entities: {},
    pagination: null
};

export const initialMultiUserAppointmentListState: EntityStateMultiUserAppointment = myDefaultMultiUserAppointmentList;

