import { Product, ProductPriceItem, ProductType } from '../../../product/product.model';
import { ProductDto, ProductPriceItemDto, ProductPriceItemSaveDto, ProductSaveDto } from './product-dto';

export class ProductMapper {

    public static mapDtoToEntity(dto: ProductDto): Product {
        const product = {...dto, id: dto.productId, productPriceItems: dto.priceItems};
        delete product.productId;
        delete product.productPriceId;
        delete product.isActive;
        delete product.note;
        delete product.productAccumulatedSum;
        delete product.priceItems;
        return product;
    }

    public static mapEntityToDto(entity: Product): ProductSaveDto {
        const dto = {
            ...entity,
            note: 'note',
            duration: String(entity.duration),
            productId: entity.id,
            productPrice: {productPriceItems: ProductMapper.createNullPrice(entity.productPriceItems, entity.productType)}
        };

        delete dto.productPriceItems;
        delete dto.id;
        return dto;
    }

    /**
     * Creates the price items and if we do not get any, we assume, that it's
     * either a free product or the user deleted it, what is basically fine.
     * We will then create a null object because internally we need one entry.
     *
     * @param items
     * @param type
     * @returns
     */
    private static createNullPrice(items: ProductPriceItem[], type: ProductType): ProductPriceItemSaveDto[] {
        const list: ProductPriceItemSaveDto[] = [];

        if (items.length === 0) {
            list.push({
                billingCode: '0',
                paymentValue: '0',
                multiplicator: '0',
                sum: '0'
            });
        } else {
            for (const item of items) {
                list.push({
                    billingCode: String(item.billingCode),
                    paymentValue: String(item.paymentValue),
                    multiplicator: String(item.multiplicator),
                    sum: String(item.sum)
                });
            }
        }
        return list;
    }

}

