<div class="overflow-wrapper">
    <div class="what-is-heading requests">
        <div class="content-wrapper">
            <div class="illustration-text-wrapper">
                <h2>{{ 'APPOINTMENT-OVERVIEW.REQUEST.WHAT-IS' | translate}}</h2>
                <p>{{ 'APPOINTMENT-OVERVIEW.REQUEST.WHAT-IS-DESCRIPTION' | translate}}</p>
            </div>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/requests.png" alt="">
    </div>
</div>
<div class="appointment-info-wrapper">
    <app-appointment-request
        *ngFor='let appointmentRequest of appointmentRequestList'
        [appointmentRequest]="appointmentRequest">
    </app-appointment-request>
</div>
