import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
    EntityStateMultiUserAppointment,
    MultiUserAppointment,
    MultiUserAppointmentRequestParams,
    MultiUserAppointmentResponse,
    MultiUserAppointmentRequestType,
    OrderByStartDate,
    Pagination
} from '../store/appointment/multi-user-appointment.entity';
import { environment } from '../../../../environments/environment';
import { DATE_TIME_FORMAT_FRONTEND, TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import {
    getIsInitSelector,
    getPaginationSelector,
    selectAll,
    selectByConferenceId
} from '../store/appointment/multi-user-appointment.selector';
import {
    cancelMultiUserAppointmentsRequest,
    fetchMultiUserAppointmentsAction,
    removeAllMultiUserAppointmentsAction
} from '../store/appointment/multi-user-appointment.action';
import moment from 'moment';
import { AppointmentStatus, appointmentStatus } from '../store/appointment/appointment-status';

const firstPage: MultiUserAppointmentRequestParams = {
    pageNumber: '1'
};

@Injectable({
    providedIn: 'root'
})
export class MultiUserAppointmentService {

    constructor(private http: HttpClient,
                private store: Store<EntityStateMultiUserAppointment>) {
    }

    public getAppointments(appointmentType: MultiUserAppointmentRequestType, queryParams= firstPage, position = null): void {
        this.store.dispatch(cancelMultiUserAppointmentsRequest());
        this.store.dispatch(removeAllMultiUserAppointmentsAction(position));
        const pageNumber = (parseInt(queryParams.pageNumber) - 1).toString();
        const today = moment().startOf('day').format(DATE_TIME_FORMAT_FRONTEND);
        const notCanceledAppointmentStatus = Object.keys(appointmentStatus).filter(status => status !== AppointmentStatus.CANCELED).join(',');

        let params: MultiUserAppointmentRequestParams = {
            ...queryParams,
            pageNumber
        };
        switch (appointmentType) {
            case MultiUserAppointmentRequestType.ALL:
                params = {
                    appointmentStatus: notCanceledAppointmentStatus
                };
                break;
            case MultiUserAppointmentRequestType.HISTORY:
                params = {
                    ...params,
                    orderByStartDate: OrderByStartDate.DESCENDING,
                    appointmentStatus: notCanceledAppointmentStatus,
                    maxStartDateTime: today
                };
                break;
            case MultiUserAppointmentRequestType.UPCOMING:
                params = {
                    ...params,
                    orderByStartDate: OrderByStartDate.ASCENDING,
                    appointmentStatus: notCanceledAppointmentStatus,
                    minStartDateTime: today
                };
                break;
            case MultiUserAppointmentRequestType.CANCELED:
                params = {
                    ...params,
                    orderByStartDate: OrderByStartDate.ASCENDING,
                    appointmentStatus: AppointmentStatus.CANCELED,
                    minStartDateTime: today
                };
                break;
        }
        this.store.dispatch(fetchMultiUserAppointmentsAction({params}));
    }

    public loadAppointments(queryParams: MultiUserAppointmentRequestParams): Observable<MultiUserAppointmentResponse> {
        const params = new HttpParams().appendAll({...queryParams});
        return this.http.get<MultiUserAppointmentResponse>(environment.appointmentsV2Endpoint, {params});
    }

    public getAllMultiUserAppointments$(): Observable<MultiUserAppointment[]> {
        return this.store.select(selectAll);
    }

    public isInit(): Observable<boolean> {
        return this.store.select(getIsInitSelector);
    }

    public getPagination$(): Observable<Pagination> {
        return this.store.select(getPaginationSelector);
    }

    public getMultiUserAppointmentByConferenceId(conferenceId: number): Observable<MultiUserAppointment> {
        return this.store.select(selectByConferenceId(conferenceId));
    }

    public static addTimeData(appointment: MultiUserAppointment): MultiUserAppointment {
        const startDateTime = TimeHelperService.utcToLocalMomentDateTime(appointment.startDateTime);
        const endDateTime = TimeHelperService.utcToLocalMomentDateTime(appointment.endDateTime);

        appointment.startDateTime = startDateTime.format(DATE_TIME_FORMAT_FRONTEND);
        appointment.endDateTime = endDateTime.format(DATE_TIME_FORMAT_FRONTEND);

        appointment.period = endDateTime.diff(startDateTime, 'minutes') + ' min';
        appointment.startTime = startDateTime.format('HH:mm');
        appointment.endTime = endDateTime.format('HH:mm');

        return appointment;
    }
}
