export interface Product {
    id?: number;
    title: string;
    description: string;
    duration: number;
    productType: ProductType;
    productPriceItems: ProductPriceItem[];
    vanishTime: number;
}

export interface ProductPriceItem {
    billingCode: number;
    paymentValue: number;
    multiplicator: number;
    sum: number;
}

export enum ProductType {
    FREE = 'free',
    NOT_FREE = 'not_free'
}
