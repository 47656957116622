import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class AnalyticsService {


    constructor() {
    }

    public trackNewPageView(link: string, name: string) {
        const obj = {
            event: 'VirtualPageview',
            virtualPageURL: link,
            virtualPageTitle: name,
        };
        this.trackSpecialEvent(obj);
    }

    public trackWrongFormInputEvent(form: UntypedFormGroup) {
        const wrongFields = [];
        Object.keys(form.controls)
            .filter((key, valu) => (!(form.get(key).valid)))
            .forEach(key => wrongFields.push(key));
        const obj = {event: 'wrongFormInput', fields: wrongFields };
        this.trackSpecialEvent(obj);
    }

    public trackSpecialEvent(obj: any) {
    }
}
