<div class="tab-list-header">
    <div class="content-wrapper">
        <div class="tab-list-item active"
             [routerLink]="[upcomingLink]"
             [ngClass]="{'active': isActiveTab(UPCOMING)}">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.UPCOMING' | translate}}</span>
        </div>
        <div class="tab-list-item"
             [routerLink]="[historyLink]"
             [ngClass]="{'active': isActiveTab(HISTORY)}">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.HISTORY' | translate}}</span>
        </div>
        <div class="tab-list-item"
             [routerLink]="[canceledLink]"
             [ngClass]="{'active': isActiveTab(CANCELED)}">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.CANCELED' | translate}}</span>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
