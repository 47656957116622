<div class="tab-list-header" *ngIf="allowedTabs.includes(activeAppointmentCreateTab)">
    <div class="content-wrapper">
        <div class="tab-list-item active"
             *ngIf="!isMUCEnabled()"
             [routerLink]="[oneTimeLink]"
             [ngClass]="{'active': activeAppointmentCreateTab === TAN}">
            <span>{{ 'APPOINTMENT.CREATE.TAN-TAB' | translate}}</span>
        </div>
        <div class="tab-list-item active"
             *ngIf="isMUCEnabled()"
             [routerLink]="[multiuserLink]"
             [ngClass]="{'active': activeAppointmentCreateTab === MULTIUSER}">
            <span>{{ 'APPOINTMENT.CREATE.CREATE-APPOINTMENT' | translate}}</span>
        </div>
        <div class="tab-list-item"
             *ngIf="!hideTabBasedOnInstitution"
             [routerLink]="[consultationHoursLink]"
             [ngClass]="{'active': activeAppointmentCreateTab === CONSULTATIONHOURS}">
            <span>{{ 'APPOINTMENT.CREATE.CONSULTATION-HOURS' | translate}}</span>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
