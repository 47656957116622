import { createAction, props } from '@ngrx/store';
import {
    CreateMultiUserTanAppointmentRequest, CreateMultiUserTanAppointmentResponse
} from '../../providers/create-multi-user-tan.types';
import { MultiUserAppointment, MultiUserAppointmentRequestParams, Pagination } from './multi-user-appointment.entity';
import { HttpErrorResponse } from '@angular/common/http';

export enum MultiUserAppointmentActions {
    CREATE = 'CREATE_MULTI_USER_APPOINTMENT',
    CREATE_SUCCESS = 'CREATE_MULTI_USER_APPOINTMENT_SUCCESS',
    CREATE_ERROR = 'CREATE_MULTI_USER_APPOINTMENT_ERROR',
    FETCH = 'FETCH_MULTI_USER_APPOINTMENTS',
    FETCH_SUCCESS = 'MULTI_USER_APPOINTMENTS_FETCH_SUCCESS',
    FETCH_ERROR = 'MULTI_USER_APPOINTMENTS_FETCH_ERROR',
    REMOVE_ALL = 'REMOVE_ALL_MULTI_USER_APPOINTMENTS',
    CANCEL = 'CANCEL_MULTI_USER_APPOINTMENTS_REQUEST'
}

export const createMultiUserAppointmentAction = createAction(
    MultiUserAppointmentActions.CREATE,
    props<{payload: CreateMultiUserTanAppointmentRequest}>()
);

export const createMultiUserAppointmentSuccessAction = createAction(
    MultiUserAppointmentActions.CREATE_SUCCESS,
    props<{payload: CreateMultiUserTanAppointmentResponse}>()
);

export const createMultiUserAppointmentErrorAction = createAction(
    MultiUserAppointmentActions.CREATE_ERROR,
    props<{error: HttpErrorResponse}>()
);

export const fetchMultiUserAppointmentsAction = createAction(
    MultiUserAppointmentActions.FETCH,
    props<{params: MultiUserAppointmentRequestParams}>()
);

export const multiUserAppointmentsFetchSuccessAction = createAction(
    MultiUserAppointmentActions.FETCH_SUCCESS,
    props<{items: MultiUserAppointment[], pagination: Pagination}>()
);

export const multiUserAppointmentsFetchErrorAction = createAction(
    MultiUserAppointmentActions.FETCH_ERROR,
    props<{error: HttpErrorResponse}>()
);

export const removeAllMultiUserAppointmentsAction = createAction(
    MultiUserAppointmentActions.REMOVE_ALL,
    (topPosition: number | null = null) => ({topPosition})
);

export const cancelMultiUserAppointmentsRequest = createAction(
    MultiUserAppointmentActions.CANCEL
);
