<ng-container *ngIf="{participants: participants$ | async} as info">
    <div class="appointment-info-for-patients"
         *ngIf="!!appointment">
        <div class="pre-info">
            <h5>
                {{ 'WAITINGROOM.VIRTUAL-WAITINGROOM' | translate}}
            </h5>
            <p>
                {{ 'WAITINGROOM.VIRTUAL-WAITINGROOM-DESCRIPTION' | translate}}
            </p>
        </div>
        <div class="full-fill" *ngFor="let participant of info.participants">
            <div class="profile-image-wrapper">
                <img src="{{participant.imageUrl}}" alt="" class="profile-image">
            </div>
            <div class="info-wrapper">
                <div>
                    <h3 class="name">
                        {{participant.fullName}}
                    </h3>
                </div>
                <table class="color-dark">
                    <tr>
                        <th>{{ 'WAITINGROOM.STARTTIME' | translate}}</th>
                        <th>{{appointment.startDateTime | date:'HH:mm'}} {{'GENERAL.TIME-MAIN-SPECIFIER' | translate}}</th>
                    </tr>
                    <tr>
                        <td>{{ 'WAITINGROOM.TOPIC' | translate}}</td>
                        <td>{{ appointment.topic | uppercase }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'WAITINGROOM.TAN' | translate}}</td>
                        <td>{{participant.tan | uppercase}}</td>
                    </tr>
                </table>
                <div class="button-section footer-right">
                    <button type="button"
                            id='submitButton'
                            class="btn btn-primary"
                            [class.btn-loading]="vonageLoading$ | async"
                            [disabled]="vonageLoading$ | async"
                            (click)="start()">
                        {{ 'WAITINGROOM.ENTER-ROOM' | translate}}
                        <span class='fas fa-arrow-alt-circle-right' aria-hidden="true"></span>
                    </button>
                    <button id="cancelButton" class="btn btn-link color-white">
                        {{ 'WAITINGROOM.TIPS' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
