import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class AppointmentRequestFormBuilder {
    public static buildAcceptOrReject(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            acceptOrDecline: ['', Validators.compose([Validators.required])]
        });
    }

    public static buildAcceptingForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            confirmation_comment: ['', Validators.compose([Validators.required])]
        });
    }

    public static buildRejectingForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            confirmation_comment: ['', Validators.compose([Validators.required])]
        });
    }
}
