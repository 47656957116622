import { Component, Input, OnInit } from '@angular/core';
import { Appointment } from '../../store/one-time-appointment/appointment.entity';
import { Profile } from '../../../profile/store/profile.entity';
import { AppointmentInfoService } from '../../providers/appointment-info.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-appointment-info-for-patients',
    templateUrl: './appointment-info-for-patients.component.html',
    styleUrls: ['./appointment-info-for-patients.component.scss']
})
export class AppointmentInfoForPatientsComponent implements OnInit {

    @Input() appointment: Appointment;
    currentProfileId: number;
    participants$: Observable<Profile[]>;
    doctorStatus$: Observable<'online' | 'offline' | 'busy'>;

    constructor(private appointmentInfoService: AppointmentInfoService) {
    }

    ngOnInit() {
        this.currentProfileId = this.appointmentInfoService.getCurrentProfileId();
        this.participants$ = this.appointmentInfoService.loadParticipants(this.appointment, this.currentProfileId);
    }
}
