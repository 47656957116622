import {
    Appointment,
    appointmentAdapter,
    EntityStateAppointment,
    initialAppointmentListState
} from './appointment.entity';
import {
    APPOINTMENT_ADD,
    APPOINTMENT_REMOVE_ALL,
    AppointmentAction,
    APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL
} from './appointment.action';


export function appointmentReducer(
    state: EntityStateAppointment = initialAppointmentListState,
    action: AppointmentAction): EntityStateAppointment {
    switch (action.type) {
        case APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL: {
            return {
                ...appointmentAdapter.addMany(<Appointment[]>action.payload, state),
                isInit: false,
                tanAppointmentId: null
            };
        }
        case APPOINTMENT_ADD: {
            const appointment = <Appointment>action.payload;
            return {
                ...appointmentAdapter.addOne(
                    appointment,
                    state
                ),
                isInit: false,
                tanAppointmentId: String(appointment.id)
            };
        }
        case APPOINTMENT_REMOVE_ALL: {
            return initialAppointmentListState;
        }
        default: {
            return state;
        }
    }
}

