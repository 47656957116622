import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConsultationHour } from '../consultation-hour.model';
import {
    AddConsultationHour,
    DeleteConsultationHour,
    LoadConsultationHours,
    UpdateConsultationHour
} from '../store/consultation-hour.actions';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { ConsultationHourDto } from './dto/consultation-hour-dto';
import { ConsultationHourMapper } from './dto/consultation-hour-mapper';
import { State } from '../store/consultation-hour.types';
import { getConsultationHourByIdSelector, selectAll } from '../store/consultation-hour.reducer';

@Injectable()
export class ConsultationHourService {

    private consultationHourEndpoint = environment.consultationHourEndpoint;

    constructor(private http: HttpClient, private store: Store<State>) {
    }

    public addConsultationHourInStore(consultationHour: ConsultationHour): void {
        this.store.dispatch(new AddConsultationHour({consultationHour}));
    }

    public addAllConsultationHourInStore(consultationHours: ConsultationHour[]): void {
        this.store.dispatch(new LoadConsultationHours({consultationHours}));
    }

    public updateConsultationHourInStore(consultationHour: ConsultationHour): void {
        this.store.dispatch(new UpdateConsultationHour({consultationHour: {id: consultationHour.id, changes: consultationHour}}));
    }

    public deleteConsultationHourInStore(consultationHour: ConsultationHour): void {
        this.store.dispatch(new DeleteConsultationHour({id: consultationHour.id}));
    }

    public getAllConsultationHoursFromStore(): Observable<ConsultationHour[]> {
        return this.store.select(selectAll);
    }

    public isAtleastOneConsultationItemExits(): Observable<boolean> {
        return this.store.select(selectAll).pipe(
            map((chList: ConsultationHour[]) => {
                let hasItems = false;
                if (chList.length === 0) {
                    return hasItems;
                }
                for (let i = 0; i < chList.length; i++) {
                    if (chList[i].consultationHourItems.length > 0) {
                        hasItems = true;
                        break;
                    }
                }
                return hasItems;
            }));
    }

    public getConsultationHourFromStore(id: number): Observable<ConsultationHour> {
        return this.store.select(getConsultationHourByIdSelector(id));
    }

    public loadAllConsultationHour(): Observable<ConsultationHour[]> {
        return this.http
            .get(this.consultationHourEndpoint, {
                headers: new HttpHeaders().set('Content-Type', 'application/json')
            })
            .pipe(
                retry(3),
                map((consultationHourDtoList: ConsultationHourDto[]) => {
                    const consultationHour: ConsultationHour[] = [];
                    consultationHourDtoList.forEach((consultationHourDto: ConsultationHourDto) => {
                        consultationHour.push(ConsultationHourMapper.mapDtoToEntity(consultationHourDto));
                    });
                    this.addAllConsultationHourInStore(consultationHour);
                    return consultationHour;
                }),
                catchError(this.handleError)
            );
    }

    public createConsultationHour(consultationHour: ConsultationHour): Observable<void> {
        const dto = ConsultationHourMapper.mapEntityToDto(consultationHour);
        return this.http
            .post(this.consultationHourEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map((responseDto: ConsultationHourDto) => {
                    consultationHour.id = responseDto.consultationHourId;
                    return this.addConsultationHourInStore(consultationHour);
                }),
                catchError(this.handleError)
            );
    }

    public updateConsultationHour(consultationHour: ConsultationHour): Observable<void> {
        const dto = ConsultationHourMapper.mapEntityToDto(consultationHour);
        return this.http
            .put(this.consultationHourEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map(() => this.updateConsultationHourInStore(consultationHour)),
                catchError(this.handleError)
            );
    }

    public deleteConsultationHour(consultationHour: ConsultationHour): Observable<void> {
        return this.http
            .delete(
                this.consultationHourEndpoint + '/' + consultationHour.id,
                {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map(() => this.deleteConsultationHourInStore(consultationHour)),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}


