<div class="overflow-wrapper">
    <div class="announces consultation-hour">
        <div class="content-wrapper">
            <div class="illustration-text-wrapper">
                <h2>{{ 'APPOINTMENT.CREATE-CONSULTATION.BANNER.HEADER' | translate }}</h2>
                <p>{{ 'APPOINTMENT.CREATE-CONSULTATION.BANNER.TEXT' | translate }}</p>
            </div>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/consultation-hours.png" alt="">
    </div>
</div>
<div class="consultation-hour-content">
    <div class='page-header content-wrapper'>
        <h1>{{ 'APPOINTMENT.CREATE-CONSULTATION.TITLE' | translate }}</h1>
        <span class="sub-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.SUB-TITLE' | translate }}</span>
    </div>
    <div id="instructions" class="card-header-beam">
        <div class='content-container content-wrapper flex-end'>
            <div class="text-color">
                <div class='icon float-left'>
                    <img src="/assets/css/images/tan-create/qa.svg">
                </div>
                <ul class="float-left max-width">
                    <li>
                        <b>{{ 'APPOINTMENT.CREATE-CONSULTATION.STEP1' | translate }}</b>&nbsp;
                        <span [innerHTML]="'APPOINTMENT.CREATE-CONSULTATION.STEP1-TEXT' | translate "> </span>&nbsp;<i
                            class="fas fa-check color-blue"></i>
                    </li>
                    <li>
                        <b>{{ 'APPOINTMENT.CREATE-CONSULTATION.STEP2' | translate }}</b>&nbsp;
                        <span [innerHTML]="'APPOINTMENT.CREATE-CONSULTATION.STEP2-TEXT' | translate "> </span>&nbsp;<i
                            class="fas fa-check color-blue"></i>
                    </li>
                </ul>
            </div>
            <div class='go-to-public-profile'>
                <a href="{{publicProfileUrl}}" target="_blank" *ngIf="isAllowToGotoPublicProfile === true"
                   class="btn btn-primary go-to-public-profile-link">
                    <span class="fas fa-external-link-alt"></span>
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.GO-TO-PROFILE-PAGE.PART-1' | translate }} <br/> {{ 'APPOINTMENT.CREATE-CONSULTATION.GO-TO-PROFILE-PAGE.PART-2' | translate }}
                </a>
                <button href="" *ngIf="isAllowToGotoPublicProfile === false"
                        class="btn btn-primary go-to-public-profile-button"
                        ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.NOT-GO-TO-PROFILE-PAGE-TOOLTIP' | translate }}"
                        placement="bottom">
                    <span class="fas fa-external-link-alt"></span>
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.GO-TO-PROFILE-PAGE.PART-1' | translate }} <br/> {{ 'APPOINTMENT.CREATE-CONSULTATION.GO-TO-PROFILE-PAGE.PART-2' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="(products$ | async)?.length <= 0; else listProducts">
        <div class='create-tan-body content-wrapper card move-up first-product-space'>
            <div class='tan-form-container card-content'>
                <div class=''>
                    <h3 class="card-title"><b> {{ 'APPOINTMENT.CREATE-CONSULTATION.STEP1' | translate }}</b> {{
                        'APPOINTMENT.CREATE-CONSULTATION.STEP1-TITLE' | translate }}</h3>
                </div>
                <app-product-form
                        (onSubmit)="onCreateProduct($event)"
                        [showDelete]="false"
                        [showCancel]="false">
                </app-product-form>
            </div>
        </div>
    </div>
    <ng-template #listProducts>
        <div class='content-wrapper card move-up product-list-space'>
            <div class='card-content'>
                <h3 class="card-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.HEADING' | translate }}</h3>
                <p class="card-sub-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.SUB-HEADING' | translate }}</p>
                <app-product-list-item
                        *ngFor="let product of products$ | async"
                        [product]="product">
                </app-product-list-item>
                <div *ngIf="createProductFormOpen" class="new-product-creation-form">
                    <span
                            class="bold">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.ADD-NEW-HEADING' | translate }}</span>
                    <div class="card blue-card ">
                        <app-product-form
                                (onSubmit)="onCreateProduct($event)"
                                [showDelete]="false"
                                [showCancel]="true"
                                (onCancel)="createProductFormOpen = false;">
                        </app-product-form>
                    </div>
                </div>
                <button class="btn btn-primary"
                        (click)="createProductFormOpen = true"
                        [hidden]="createProductFormOpen"><i class="fal fa-plus"></i>
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.ADD-PRODUCT' | translate }}
                </button>
            </div>
        </div>
    </ng-template>
    <div class="relative">
        <div class="content-wrapper step-order-notice"
             *ngIf="(products$ | async)?.length <= 0">
            <h3>{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.STEP-ORDER-NOTICE' | translate }}</h3>
        </div>
        <div class="time-frame-listing content-wrapper card"
             [class.blurred]="(products$ | async)?.length <= 0">
            <div class='card-content'>
                <h3 class="card-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.STEP2-TITLE' | translate }}</h3>
                <p class="card-sub-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.STEP2-TEXT' | translate }}</p>
                <app-daily-timeframe
                        *ngFor="let dayOfWeek of [1, 2, 3, 4, 5, 6, 0]"
                        [dayOfWeek]="dayOfWeek">
                </app-daily-timeframe>
            </div>
        </div>
    </div>
</div>
