<div class='appointment-success-wrapper'>
    <div class='appointment-success-container'>
        <div *ngIf="isEmailSent === 'false'">
            <div id='check'>
                <span class="fal fa-check fa-5x" aria-hidden="true"></span>
            </div>
            <div id='header'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.HEADER' | translate }}</div>
        </div>
        <div *ngIf="isEmailSent === 'true'">
            <div id='check'>
                    <img
                    src="/assets/img/general/mail-check.svg"
                    class="email-sent"
                    alt="email-sent">
            </div>
            <div id='header'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.HEADER-WITH-EMAIL' | translate }}</div>
        </div>
        <div id='tan-container'>
            <span class='tan-text'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN' | translate }}: </span>
            <div class='tan'
                 ngxClipboard
                 [cbContent]="(tanLink$ | async)"
                 (click)='copiedSuccessful()'>{{(tan$ | async)}}</div>
            <span id='copy-to-clipboard'
                  class='fal fa-copy fa-1x'
                  ngxClipboard
                  [cbContent]="(tanLink$ | async)"
                  (click)='copiedSuccessful()'></span>
        </div>
        <div id='show-copied' *ngIf="!hideCopiedSuccessful">{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-COPIED' |
            translate }}
        </div>
        <div id='footer-text'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.FOOTER-TEXT' | translate }}</div>
        <div id='footer-buttons'>
            <button type="button"
                    id='gotoWaitingRoomButton'
                    (click)='goAppointmentUpcoming()'
                    class="btn btn-link">
                {{'APPOINTMENT.TAN-CREATE-SUCCESSFUL.GO-TO-WAITINGROOM-BUTTON-TEXT' | translate }}
            </button>
            <button type="button"
                    id='createNewAppointmentButton'
                    (click)='goToCreateAppointment()'
                    class="btn btn-primary">
                {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.GO-TO-TAN-CREATE-BUTTON-TEXT' | translate }}
            </button>
        </div>
    </div>
</div>
