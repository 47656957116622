import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppointmentRequest, EntityStateAppointmentRequest } from '../store/appointment-request.entity';
import { MapService } from './map.service';
import { selectAllAppointmentRequests } from '../store/appointment-request.selector';
import { Observable, of } from 'rxjs';
import { RawAppointmentRequest } from './appointment-request.types';

@Injectable()
export class AppointmentRequestService {
    private getAppointmentRequestsEndpoint = environment.getAppointmentRequestsEndpoint;
    private putAppointmentRequestAcceptEndpoint = environment.putAppointmentRequestAcceptEndpoint;
    private putAppointmentRequestRejectionEndpoint = environment.putAppointmentRequestRejectionEndpoint;

    constructor(private http: HttpClient, private store: Store<EntityStateAppointmentRequest>) {
    }

    public getAppointmentRequests(): Observable<AppointmentRequest[]> {
        return this.http.get(this.getAppointmentRequestsEndpoint, {}).pipe(
            switchMap((rawAppointmentRequest: RawAppointmentRequest[]) => {
                if (rawAppointmentRequest.length === 0) {
                    return of([]);
                }
                const rawAppointmentRequests: RawAppointmentRequest[] = (<RawAppointmentRequest[]>rawAppointmentRequest);
                const appointmentRequestsObjList: AppointmentRequest[] = rawAppointmentRequests.map(
                    (raw: RawAppointmentRequest) => MapService.convertToAppointmentRequest(raw)
                );
                return of(appointmentRequestsObjList);
            }));
    }

    public acceptAppointmentRequest(appointmentRequest: AppointmentRequest): Observable<{[key: string]: any}> {
        return this.http.put(this.putAppointmentRequestAcceptEndpoint, {
            appointment_request_id: appointmentRequest.id,
            appointment_id: appointmentRequest.appointmentId,
            patient_profile_id: appointmentRequest.profileId,
            confirmation_comment: appointmentRequest.confirmationComment
        });
    }

    public rejectAppointmentRequest(appointmentRequest: AppointmentRequest): Observable<{[key: string]: any}> {
        return this.http.put(this.putAppointmentRequestRejectionEndpoint, {
            appointment_request_id: appointmentRequest.id,
            confirmation_comment: appointmentRequest.confirmationComment,
        });
    }

    public getAppointmentRequestList(): Observable<AppointmentRequest[]> {
        return this.store.select(selectAllAppointmentRequests).pipe(filter(list => list !== null));
    }

    public getNumberOfOpenAppointmentRequests(): Observable<number> {
        return this.getAppointmentRequestList().pipe(map(list => list.length));
    }
}
