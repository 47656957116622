import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig, AppConfigKey, AppConfigObject } from './app-config.types';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

    constructor(@Inject(APP_CONFIG) private readonly appConfig: AppConfig) {
    }

    public getConfig(configKey: AppConfigKey): AppConfigObject {
        return this.appConfig[configKey];
    }
}
