import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Product} from '../product.model';
import {ProductActions, ProductActionTypes} from './product.actions';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppointmentTypeState, selectAppointmentTypeState} from '../../store/appointment-type.state';
import {ProductState} from './product.types';

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: ProductState = adapter.getInitialState({
    // additional entity state properties
});

export function reducer(state = initialState,
                        action: ProductActions): ProductState {
    switch (action.type) {
        case ProductActionTypes.AddProduct: {
            return adapter.addOne(action.payload.product, state);
        }

        case ProductActionTypes.AddProducts: {
            return adapter.addMany(action.payload.products, state);
        }

        case ProductActionTypes.UpdateProduct: {
            return adapter.updateOne(action.payload.product, state);
        }

        case ProductActionTypes.UpdateProducts: {
            return adapter.updateMany(action.payload.products, state);
        }

        case ProductActionTypes.DeleteProduct: {
            return adapter.removeOne(action.payload.id, state);
        }

        case ProductActionTypes.DeleteProducts: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case ProductActionTypes.LoadProducts: {
            return adapter.setAll(action.payload.products, state);
        }

        case ProductActionTypes.ClearProducts: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}
export const selectProductsState = createSelector(
    selectAppointmentTypeState,
    (state: AppointmentTypeState) => state.appointmentProduct
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors(selectProductsState);

export function getProductByIdSelector(id: number): MemoizedSelector<ProductState, Product> {
    const productIdState = (state: ProductState) => state.entities[id];

    const productIdSelector = createSelector(selectProductsState, productIdState);
    return productIdSelector;
}
