import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InstitutionService } from '../../../institution-settings/providers/institution.service';
import {
    APPOINTMENT_CREATE_CONSULTATIONHOURS,
    APPOINTMENT_CREATE_TAN,
    APPOINTMENT_CREATE_MULTIUSER,
    CREATE_APPOINTMENT_FAILURE,
    CREATE_APPOINTMENT_SUCCESS
} from '../../../../routes';
import { FeatureFlag } from "../../../feature-flag/feature-flag.types";
import { FeatureFlagService } from "../../../feature-flag/providers/feature-flag.service";

export enum AppointmentCreateTabs {
    TAN = 'tan',
    CONSULTATIONHOURS = 'consultation-hour',
    MULTIUSER = 'multiuser'
}

enum HiddenTabs {
    MULTIUSER_SUCCESS= 'multiuser-success',
    MULTIUSER_FAILURE= 'multiuser-failure'
}

type Tab = AppointmentCreateTabs | HiddenTabs;

@Component({
    selector: 'app-create-appointment',
    templateUrl: './create-appointment.component.html',
    styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent implements OnInit {
    TAN = AppointmentCreateTabs.TAN;
    CONSULTATIONHOURS = AppointmentCreateTabs.CONSULTATIONHOURS;
    MULTIUSER = AppointmentCreateTabs.MULTIUSER;
    oneTimeLink = APPOINTMENT_CREATE_TAN;
    multiuserLink = APPOINTMENT_CREATE_MULTIUSER;
    consultationHoursLink = APPOINTMENT_CREATE_CONSULTATIONHOURS;
    allowedTabs: Tab[] = Object.values(AppointmentCreateTabs);

    public activeAppointmentCreateTab: Tab = AppointmentCreateTabs.TAN;
    public hideTabBasedOnInstitution: boolean;

    constructor(private translate: TranslateService,
                private institutionService: InstitutionService,
                private featureFlagService: FeatureFlagService,
                private router: Router) {
        this.listForPathChanges();
    }

    ngOnInit(): void {
        this.hideTabBasedOnInstitution = this.institutionService.hideTabsBasedOnInstitutionName();
    }

    private listForPathChanges(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab(event.url);
            }
        });
    }

    private setActiveTab(url: string): void {
        this.changeActiveTab(this.mapUrlToTab(url));
    }

    private mapUrlToTab(url: string): Tab {
        switch (url) {
            case APPOINTMENT_CREATE_TAN: {
                return AppointmentCreateTabs.TAN;
            }
            case APPOINTMENT_CREATE_CONSULTATIONHOURS: {
                return AppointmentCreateTabs.CONSULTATIONHOURS;
            }
            case APPOINTMENT_CREATE_MULTIUSER: {
                return AppointmentCreateTabs.MULTIUSER;
            }
            case CREATE_APPOINTMENT_SUCCESS: {
                return HiddenTabs.MULTIUSER_SUCCESS;
            }
            case CREATE_APPOINTMENT_FAILURE: {
                return HiddenTabs.MULTIUSER_FAILURE;
            }
            default: {
                return AppointmentCreateTabs.TAN;
            }
        }
    }

    public changeActiveTab(tabName: Tab): void {
        this.activeAppointmentCreateTab = tabName;
    }

    public isActiveTab(tabName: AppointmentCreateTabs): boolean {
        return this.activeAppointmentCreateTab === tabName;
    }

    private isMUCEnabled(): boolean {
        return this.featureFlagService.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL);
    }
}
