import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { conferencesFeatureKey, ConferenceState, selectEntities, selectAll } from './conference.reducer';

export const selectConferenceState = createFeatureSelector<ConferenceState>(conferencesFeatureKey);

export const selectAllConferences = createSelector(
    selectConferenceState,
    selectAll
);

export const selectAllEntitiesConferences = createSelector(
    selectConferenceState,
    selectEntities
);

export const selectConferenceById = (id: number) => createSelector(
    selectAllEntitiesConferences,
    conferences => conferences[id]
);

const getActiveId = (state: ConferenceState) => state.activeId;

export const selectActiveConferenceId = createSelector(
    selectConferenceState,
    getActiveId
);

const getToken = (state: ConferenceState) => state.token;

export const selectToken = createSelector(
    selectConferenceState,
    getToken
);

export const selectActiveConference = createSelector(
    selectConferenceState,
    selectActiveConferenceId,
    (conferences, activeId) => conferences[activeId]
);
