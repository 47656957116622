<div class="appointment-info card active"
     *ngIf="!!appointment && participants$ | async as participants"
     [ngClass]="{'canceled': appointment.appointmentStatus === canceledAppointment}">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-2 col-sm-12 d-flex flex-column no-padding">
                <div class="appointment-start-time">
                    <i class="fa fa-clock"></i>
                    <span *ngIf="isAppointmentToday">
                            {{ 'APPOINTMENT.INFO.TODAY' | translate }} {{appointment.startDateTime | date:'HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}
                        </span>
                    <span *ngIf="!isAppointmentToday">
                            {{appointment.startDateTime | date:'dd.LL.yyyy HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}
                        </span>
                    <span class="appointment-start-time-duration">&nbsp;{{appointment.period}}</span>
                </div>
                <div class="appointment-duration">
                    <span>{{appointment.period}}</span>
                </div>
                <div class="appointment-end-time">
                    <i class="fa fa-dot-circle"></i> {{appointment.endDateTime | date:'HH:mm'}} {{'APPOINTMENT.INFO.TIME-MAIN-SPECIFIER' | translate}}
                </div>
            </div>
            <div class="col-md-6 col-sm-12 no-padding patient-info-wrapper">
                <div class="participant" *ngFor="let participant of participants | slice:0: visibleParticipants">
                    <div class="patient-info mb-2 clearfix">
                        <span *ngIf="appointment.appointmentStatus === canceledAppointment"
                              class="canceled-text">{{ 'APPOINTMENT.STATUS.CANCELED' | translate }}</span>
                        <ng-container *ngIf="appointment.appointmentStatus !== canceledAppointment">
                            <i class="fal fa-file-user"></i>&nbsp;
                            <span *ngIf="participant?.tan" class="tan"
                                  ngxClipboard [cbContent]="tanLoginLink + participant.tan"
                                  ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-COPIED' | translate }}"
                                  [autoClose]="true" placement="bottom"
                                  triggers="manual" #tooltip="ngbTooltip" (cbOnSuccess)="toggleTooltip(tooltip)">
                                {{participant.tan}}
                            </span>
                        </ng-container>
                        <span class="user-name bold" *ngIf="participant?.fullName">{{participant.fullName}}</span>
                    </div>
                    <div *ngIf="participant?.birthday" class="patient-info">
                        <span class="label">{{ 'APPOINTMENT.INFO.PATIENT-EMAIL-BIRTH-DATE' | translate }}:&nbsp;</span>
                        <span>{{ participant?.birthday | date:('dd.MM.yyyy') }}</span>
                    </div>
                    <div *ngIf="participant?.email" class="patient-info">
                        <span class="label">{{ 'APPOINTMENT.INFO.PATIENT-EMAIL-LABEL' | translate }}:</span>&nbsp;
                        <a href="mailto:{{ participant.email }}"
                           class="btn-link font-weight-normal">{{ participant.email }}</a>
                    </div>
                </div>
                <div class="mb-2" *ngIf="participants.length > defaultVisibleParticipants">
                    <button class="btn btn-link no-padding toggle-participants"
                            (click)="toggleParticipants(participants.length)">
                        <ng-container *ngIf="visibleParticipants === defaultVisibleParticipants else showLess">
                            {{ 'APPOINTMENT.INFO.PATIENTS-TOGGLE-BTN-SHOW-MORE' | translate: {numOfPart: participants.length - defaultVisibleParticipants} }}
                            <span><i class="fa fa-caret-up"></i></span>
                        </ng-container>
                        <ng-template #showLess>
                            {{ 'APPOINTMENT.INFO.PATIENTS-TOGGLE-BTN-SHOW-LESS' | translate }}
                            <span><i class="fa fa-caret-down"></i></span>
                        </ng-template>
                    </button>
                </div>
                <div class="message" *ngIf="appointment.appointmentMessage">
                    <span class="bold">{{ 'APPOINTMENT.INFO.APPOINTMENT_MESSAGE' | translate }}: </span>
                    {{ appointment.appointmentMessage }}
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 patient-info-wrapper appointment-action-buttons"
                 *ngIf="appointmentInfoType === upComingAppointmentInfoType">
                <button type="button"
                        id='submitButton'
                        class="btn btn-primary"
                        [class.btn-loading]="vonageLoading$ | async"
                        [disabled]="vonageLoading$ | async"
                        (click)="start()">
                    <span>
                        <i class="fa fa-caret-right"></i>{{ 'APPOINTMENT.INFO.START' | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>

