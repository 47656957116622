<div class="overflow-wrapper">
    <div class="what-is-heading history">
        <div class="content-wrapper">
            <div class="illustration-text-wrapper">
                <h2>{{ 'APPOINTMENT-OVERVIEW.HISTORY.WHAT-IS' | translate}}</h2>
                <p>{{ 'APPOINTMENT-OVERVIEW.HISTORY.WHAT-IS-DESCRIPTION' | translate}}</p>
            </div>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/history.png" alt="">
    </div>
</div>
<ng-container *ngIf="{history: historyAppointments$ | async, isInit: isInit$ | async} as appointments">
    <div class="appointment-info-wrapper" *ngIf="appointments?.history?.length > 0">
        <app-appointment-info
            *ngFor="let appointment of appointments?.history"
            [appointment]="appointment"
            [appointmentInfoType]="HISTORY_APPOINTMENT_INFO_TYPE">
        </app-appointment-info>
    </div>
    <div *ngIf="!appointments?.history?.length && !appointments.isInit"
        class="content-wrapper empty-appointments appointment-overview-wrapper">
        <h3 class="no-appointment-message">{{ 'APPOINTMENT-OVERVIEW.HISTORY.EMPTY-APPOINTMENTS' | translate }}</h3>
    </div>
</ng-container>
