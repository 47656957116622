import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { Store } from '@ngrx/store';
import { selectActiveConference, selectActiveConferenceId, selectConferenceById } from '../store/conference.selectors';
import { Conference } from '../store/conference.model';
import { Observable } from 'rxjs';
import { ConferenceState } from '../store/conference.reducer';
import { ConferenceToken } from '../../conference/store/conference.entity';
import { Activate } from '../store/conference.actions';

export interface ConferenceResource {
    tokenId: string;
    conferenceId: string;
    token: string;
    profileId: string;
}

export interface ConferenceResponse {
    success?: boolean;
    items: ConferenceResource[];
}

@Injectable()
export class ConferenceService {
    private conferenceEndpoint = environment.conferenceListEndpoint;

    constructor(private http: HttpClient,
                private profileSelectService: ProfileSelectService,
                private store: Store<ConferenceState>) {
    }

    public getConferenceById(id): Observable<Conference> {
        return this.store.select(selectConferenceById(id));
    }

    public getConference(id: number): Observable<Conference> {
        const currentProfileId = this.profileSelectService.getCurrentProfileId();
        return this.http.get(this.conferenceEndpoint + '/' + id, {}).pipe(
            map((response: ConferenceResponse) => this.mapToConferenceEntity(response.items, id, currentProfileId)));
    }

    public getActiveConference(): Observable<Conference> {
        return this.store.select(selectActiveConference);
    }

    public getActiveConferenceId(): Observable<number> {
        return this.store.select(selectActiveConferenceId);
    }

    public activateConference(conferenceId: number): void {
        return this.store.dispatch(new Activate({id: conferenceId}));
    }

    public mapToConferenceEntity(resources: ConferenceResource[], conferenceId: number, currentProfileId: number): Conference {
        const conference: Conference = {id: Number(conferenceId), myToken: null, userTokens: []};
        resources.map((cr: ConferenceResource) => {
            const token: ConferenceToken = {id: Number(cr.tokenId), profileId: Number(cr.profileId), token: cr.token};
            if (currentProfileId === token.profileId) {
                conference.myToken = token;
            } else {
                conference.userTokens.push(token);
            }
        });
        return conference;
    }
}
