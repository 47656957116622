<div id='create-tan-form-container'>
    <div class='page-header content-wrapper'>
        <h1>{{ 'APPOINTMENT.TAN-CREATE.DESCRIPTION-HEADING' | translate }}</h1>
        <span class="sub-title">{{ 'APPOINTMENT.TAN-CREATE.DESCRIPTION-SUBTITLE' | translate }}</span>
    </div>
    <div id="instructions" class="card-header-beam">
        <div class='content-container content-wrapper'>
            <div class='icon'>
                <img src="/assets/css/images/tan-create/qa.svg">
            </div>
            <ul>
                <li>
                    <b>1. </b>{{ 'APPOINTMENT.TAN-CREATE.INSTRUCTION.1' | translate }}
                </li>
                <li>
                    <b>2. </b>{{ 'APPOINTMENT.TAN-CREATE.INSTRUCTION.2' | translate }}
                </li>
            </ul>
        </div>
    </div>
    <div class='create-tan-body content-wrapper card move-up'>
        <div class='tan-form-container card-content'>
            <form [formGroup]="tanCreateForm" role="form">
                <div class=''>
                    <h3 class="card-title">{{ 'APPOINTMENT.TAN-CREATE.MEETING' | translate }}</h3>
                    <span class="card-subtitle">{{ 'APPOINTMENT.TAN-CREATE.MEETING-SUBTITLE' | translate }}</span>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="tanDate">
                            <span class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.DATE' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.DATE-TOOLTIP' | translate"></span>
                        </label>
                        <div class="input-group datepicker">
                            <input type="text"
                                   formControlName="tanDate"
                                   class="form-rounded form-control"
                                   id="tanDate"
                                   [placeholder]="'APPOINTMENT.TAN-CREATE.DATE-INPUT-PLACEHOLDER' | translate"
                                   ngbDatepicker
                                   [navigation]="'arrows'"
                                   [outsideDays]="'collapsed'"
                                   [markDisabled]=" datePickerDateCompare.isLesserThanToday"
                                   #d="ngbDatepicker"
                                   [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.DATE' | translate"
                                   placement="bottom"
                                   triggers="manual"
                                   appInvalidDirective>
                            <span id="calendar-icon"
                                  class="fa fa-calendar-alt"
                                  aria-hidden="true"
                                  (click)="d.toggle()"></span>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="tanBeginning">
                            <span class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.BEGINNING' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.BEGINNING-TOOLTIP' | translate"></span>
                        </label>
                        <div class="">
                            <app-time-picker id="tanBeginning"
                                             [selectedTime]="currentTimeObject"
                                             class='time-picker'
                                             formControlName="tanBeginning"
                                             ngDefaultControl
                                             (selectedTimeEmitter)="setEndTime($event)">
                            </app-time-picker>
                            <div class='end-time-display'>
                                <span class='fal fa-arrow-right' aria-hidden="true"></span>{{beggingTextAddon}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="tanDuration">
                            <span class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.DURATION' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.DURATION-TOOLTIP' | translate"></span>
                        </label>
                        <select id="tanDuration"
                                class="form-control input-select"
                                formControlName="tanDuration"
                                [compareWith]="compareByItem"
                                (change)="onDurationChange()"
                                [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.DURATION' | translate"
                                triggers="manual"
                                appInvalidDirective>
                            <option *ngFor="let duration of durations" [ngValue]="duration">{{duration.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="terminDescription">
                            {{ 'APPOINTMENT.TAN-CREATE.TERMIN-DESCRIPTION' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.TERMIN-DESCRIPTION-TOOLTIP' | translate">
                            </span>
                            <span class="optional"> ({{ 'APPOINTMENT.TAN-CREATE.OPTIONAL' | translate }})</span>
                        </label>
                        <input type="text"
                               formControlName="terminDescription"
                               class="form-rounded form-control"
                               id="terminDescription"
                               placement="bottom"
                               triggers="manual"
                               [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.FIRST-NAME' | translate"
                               appInvalidDirective>
                    </div>
                </div>
                <div class="row">
                    <div class="ebm-controlles col-md-12">
                        <div class="form-group clearfix">
                            <label for="ebm-check-box">
                                {{ 'APPOINTMENT.TAN-CREATE.EBM.HEADER' | translate }}
                                <span class="optional">({{ 'APPOINTMENT.TAN-CREATE.OPTIONAL' | translate }})</span>
                            </label>
                            <br>
                            <div class="checkbox-wrapper outlined-checkbox">
                                <input type="checkbox"
                                       id="ebm-check-box"
                                       formControlName="isEbmCheckBox">
                            </div>
                            <div class="emb-help">
                                <div class="title">
                                    {{ 'APPOINTMENT.TAN-CREATE.EBM.TITLE' | translate }}
                                </div>
                                <div class="subtitle">
                                    {{ 'APPOINTMENT.TAN-CREATE.EBM.SUBTITLE' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class='form-group-header'>
                    <span class="card-subtitle">
                        {{ 'APPOINTMENT.TAN-CREATE.PATIENT-SUBTITLE' | translate }}
                    </span>
                </div>
                <div formArrayName="patients"
                     *ngFor="let patient of tanCreateForm.controls.patients?.value as patients;
                    let i = index; trackBy:trackByFn" class="form-row content-box">
                    <ng-container [formGroupName]="i">
                        <div class="form-group col-md-12">
                            <h5 class="patient-title">
                                {{ 'APPOINTMENT.TAN-CREATE.PATIENT' | translate }}-{{i + 1}}
                                <button [disabled]="patients.length === 1" type="button"
                                        class="btn btn-primary btn-circle remove-patient" (click)="removePatient(i)">
                                    <span class="fas fa-minus" aria-hidden="true"></span>
                                </button>
                            </h5>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="salutation-{{i}}">
                                       <span
                                           class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.SALUTATION' | translate }}
                                <span class='fal fa-question-circle'
                                      placement="bottom"
                                      aria-hidden="true"
                                      ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE.SALUTATION-TOOLTIP' | translate }}"></span>
                            </label>
                            <select formControlName="salutation"
                                    id="salutation-{{i}}"
                                    class="form-control input-select"
                                    [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.SALUTATION' | translate"
                                    placement="bottom"
                                    triggers="manual"
                                    appInvalidDirective>
                                <option value="" disabled selected>
                                    {{'APPOINTMENT.TAN-CREATE.GENDER-INPUT-PLACEHOLDER' | translate}}
                                </option>
                                <option *ngFor="let gender of genders"
                                        [ngValue]="gender">{{gender.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="firstName-{{i}}">
                                       <span
                                           class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.FIRST-NAME' | translate }}
                                <span class='fal fa-question-circle'
                                      aria-hidden="true"
                                      placement="bottom"
                                      [ngbTooltip]="'APPOINTMENT.TAN-CREATE.FIRST-NAME-TOOLTIP' | translate"></span>
                            </label>
                            <input type="text"
                                   formControlName="firstName"
                                   class="form-rounded form-control"
                                   id="firstName-{{i}}"
                                   [placeholder]="'APPOINTMENT.TAN-CREATE.FIRSTNAME-INPUT-PLACEHOLDER' | translate"
                                   [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.FIRST-NAME' | translate"
                                   placement="bottom"
                                   triggers="manual"
                                   appInvalidDirective>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="lastName-{{i}}">
                                       <span
                                           class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.LAST-NAME' | translate }}
                                <span class='fal fa-question-circle'
                                      aria-hidden="true"
                                      placement="bottom"
                                      [ngbTooltip]="'APPOINTMENT.TAN-CREATE.LAST-NAME-TOOLTIP' | translate"></span>
                            </label>
                            <input type="text"
                                   formControlName="lastName"
                                   class="form-rounded form-control"
                                   id="lastName-{{i}}"
                                   [placeholder]="'APPOINTMENT.TAN-CREATE.SURNAME-INPUT-PLACEHOLDER' | translate"
                                   [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.LAST-NAME' | translate"
                                   placement="bottom"
                                   triggers="manual"
                                   appInvalidDirective>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="patient-email-{{i}}">
                                {{ 'APPOINTMENT.TAN-CREATE.PATIENT-EMAIL.HEADER' | translate }}
                                <span class="fal fa-question-circle"
                                      aria-hidden="true"
                                      placement="bottom"
                                      [ngbTooltip]="'APPOINTMENT.TAN-CREATE.PATIENT-EMAIL.TOOLTIP' | translate">
                            </span>
                                <span class="optional"> ({{ 'APPOINTMENT.TAN-CREATE.OPTIONAL' | translate }})</span>
                            </label>
                            <input type="text"
                                   formControlName="patientEmail" class="form-rounded form-control"
                                   email="true"
                                   id="patient-email-{{i}}"
                                   [placeholder]="'APPOINTMENT.TAN-CREATE.PATIENT-EMAIL.INPUT-PLACEHOLDER' | translate"
                                   [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.PATIENT-EMAIL' | translate"
                                   placement="bottom"
                                   triggers="manual"
                                   appInvalidDirective>
                        </div>
                        <div formGroupName="birthday" class="form-group col-md-6">
                            <label for="birthday">
                                {{ 'APPOINTMENT.TAN-CREATE.BIRTHDAY' | translate }}
                                <span class='fal fa-question-circle'
                                      aria-hidden="true"
                                      placement="bottom"
                                      [ngbTooltip]="'APPOINTMENT.TAN-CREATE.BIRTHDAY-TOOLTIP' | translate"></span>
                                <span class="optional"> ({{ 'APPOINTMENT.TAN-CREATE.OPTIONAL' | translate }})</span>
                            </label>
                            <div class="form-group form-inline"
                                 id="birthday-container"
                                 [class.ng-invalid]="!(patient.birthday.valid)"
                                 [class.ng-valid]="patient.birthday.valid"
                                 [class.ng-dirty]="patient.birthday.dirty"
                                 [class.ng-touched]="patient.birthday.touched">
                                <input type="text"
                                       formControlName="birthDay"
                                       size="2"
                                       (input)="goToNextInput($event)"
                                       maxlength="2"
                                       class="birthday-fields birthday-fields-size-2"
                                       id="birthDay"
                                       [placeholder]="'APPOINTMENT.TAN-CREATE.PLACEHOLDER.DAY' | translate">.
                                <input type="text"
                                       formControlName="birthMonth"
                                       size="2"
                                       (input)="goToNextInput($event)"
                                       maxlength="2"
                                       class="birthday-fields birthday-fields-size-2"
                                       id="birthMonth"
                                       [placeholder]="'APPOINTMENT.TAN-CREATE.PLACEHOLDER.MONTH' | translate">.
                                <input type="text"
                                       formControlName="birthYear"
                                       size="4"
                                       maxlength="4"
                                       class="birthday-fields birthday-fields-size-4"
                                       id="birthYear"
                                       [placeholder]="'APPOINTMENT.TAN-CREATE.PLACEHOLDER.YEAR' | translate"
                                       [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.BIRTHDAY' | translate"
                                       placement="bottom"
                                       triggers="manual"
                                       appInvalidDirective>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="add-patient-sector">
                    <span class="add-button-right weight-500">
                        {{ 'APPOINTMENT.TAN-CREATE.ADD-PATIENT' | translate }}
                        <button type="button"
                                id='addPatient'
                                class="btn btn-primary btn-circle"
                                (click)="addPatient()">
                            <span class='fas fa-plus' aria-hidden="true"></span>
                        </button>
                    </span>
                </div>
                <hr/>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="message">
                            {{ 'APPOINTMENT.TAN-CREATE.MESSAGE' | translate }}
                            <span class='fal fa-question-circle'
                                  aria-hidden="true"
                                  placement="bottom"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.PATIENT-EMAIL-MESSAGE.TOOLTIP' | translate">
                            </span>
                            <span class="optional"> ({{ 'APPOINTMENT.TAN-CREATE.OPTIONAL' | translate }})</span>
                        </label>
                        <textarea id="message"
                                  class="form-rounded form-control textarea"
                                  rows="20"
                                  formControlName="message"
                                  placement="bottom"
                                  triggers="manual"
                                  placeholder="{{'APPOINTMENT.TAN-CREATE.PLACEHOLDER.ABOUT-ME' | translate}}"
                                  [ngbTooltip]="'APPOINTMENT.TAN-CREATE.ERROR.REQUIRED.FIRST-NAME' | translate"
                                  appInvalidDirective>
                        </textarea>
                    </div>
                </div>
                <div id="form-footer" class="card-footer clearfix">
                    <div class="footer-left">
                        <span class='required'>*</span>{{ 'APPOINTMENT.TAN-CREATE.REQUIRED-FIELDS' | translate }}
                    </div>
                    <div class="footer-right">
                        <button type="button"
                                id='submitButton'
                                class="btn btn-primary"
                                [class.btn-loading]="loading"
                                [disabled]="loading"
                                (click)="onSubmit()">{{ 'APPOINTMENT.TAN-CREATE.SUBMIT-BUTTON-TEXT' | translate }}
                            <span class='fal fa-check-circle' aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
