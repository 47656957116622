import { Action } from '@ngrx/store';

export class AppAction implements Action {
    type: string;
}

// APP_SWITCHING_TO_ANONYMOUS_STATE
export const APP_INIT_ANONYMOUS_STATE = 'APP_INIT_ANONYMOUS_STATE';

export class AppInitAnonymousStateAction implements Action {
    public type = APP_INIT_ANONYMOUS_STATE;
}

// APP_IS_IN_ANONYMOUS_STATE
export const APP_CHANGE_TO_ANONYMOUS_STATE = 'APP_CHANGE_TO_ANONYMOUS_STATE';

export class AppChangeToAnonymousStateAction implements Action {
    public type = APP_CHANGE_TO_ANONYMOUS_STATE;
}

// APP_SWITCHING_TO_AUTHENTICATE_STATE
export const APP_INIT_AUTHENTICATED_STATE = 'APP_INIT_AUTHENTICATED_STATE';

export class AppInitAuthenticatedStateAction implements Action {
    public type = APP_INIT_AUTHENTICATED_STATE;
}

// APP_IS_IN_AUTHENTICATED_STATE
export const APP_CHANGE_TO_AUTHENTICATED_STATE = 'APP_CHANGE_TO_AUTHENTICATED_STATE';

export class AppChangeToAuthenticatedStateAction implements Action {
    public type = APP_CHANGE_TO_AUTHENTICATED_STATE;
}

export const APP_RELOADED_AND_USER_ALREADY_LOGGED_IN = 'APP_RELOADED_AND_USER_ALREADY_LOGGED_IN';

export class AppReloadedAndUserAlreadyLoggedInAction implements Action {
    public type = APP_RELOADED_AND_USER_ALREADY_LOGGED_IN;
}
