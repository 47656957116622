import { switchMap } from 'rxjs/operators';
import { Appointment, AppointmentTypes } from '../store/one-time-appointment/appointment.entity';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { AppointmentService } from './appointment.service';
import { ProfileAddAction } from '../../profile/store/profile.action';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { ConferenceResponse, ConferenceService } from '../../conference/providers/conference.service';
import { ConferenceAddAction } from '../../conference/store/conference.action';
import { AppointmentAddAction, CreateTanAppointmentCompletedAction } from '../store/one-time-appointment/appointment.action';
import { AppointmentRequestAcceptAction } from '../../appointment-request/store/appointment-request.action';
import { Observable, of, throwError as _throw } from 'rxjs';
import { CreateTanAppointmentDTO, CreateTanAppointmentRequest, CreateTanConferenceRequest, CreateTanResponse } from './create-tan.types';
import { Conference } from '../../conference-v2/store/conference.model';
import { AddConference } from '../../conference-v2/store/conference.actions';

@Injectable()
export class CreateTanService {
    private createTanEndpoint = environment.createTanEndpoint;
    private createTanConferenceEndpoint = environment.createTanConferenceEndpoint;
    private createTanAppointmentEndpoint = environment.createTanAppointmentEndpoint;

    constructor(private http: HttpClient,
                private profileSelectService: ProfileSelectService,
                private conferenceService: ConferenceService,
                private appointmentService: AppointmentService) {
    }

    public createTan(dto: CreateTanAppointmentDTO): Observable<CreateTanAppointmentDTO> {
        return this.http.post(this.createTanEndpoint, dto.formValues).pipe(switchMap(result => {
            if (result.hasOwnProperty('profile') && result.hasOwnProperty('tan')) {
                const validResponse = <CreateTanResponse>result;
                dto.profile = validResponse.profile;
                dto.tan = validResponse.tan;

                if (dto.formValues.tan.email !== '') {
                    dto.profile.email = dto.formValues.tan.email;
                }

                if (dto.formValues.user.birthday !== '') {
                    dto.profile.birthday = dto.formValues.user.birthday;
                }

                return of(dto);
            } else {
                return _throw(new Error('Wrong response object by tan creation'));
            }
        }));
    }

    public createTanConference(dto: CreateTanAppointmentDTO): Observable<CreateTanAppointmentDTO> {
        const requestData: CreateTanConferenceRequest = this.prepareCreateConferenceRequest(dto.getProfileId());
        return this.http.post(this.createTanConferenceEndpoint, requestData).pipe(switchMap(result => {
            if (result.hasOwnProperty('items')) {
                const response = <ConferenceResponse>result;
                const conference = this.conferenceService.mapToConferenceEntity(
                    response.items,
                    response.items[0].conferenceId,
                    this.profileSelectService.getCurrentProfileId()
                );
                dto.conference = conference;

                return of(dto);
            } else {
                return _throw(new Error('Wrong response object by tan conference creation'));
            }
        }));
    }

    private prepareCreateConferenceRequest(patientProfileId: number): CreateTanConferenceRequest {
        const doctorId = this.profileSelectService.getCurrentProfileId();

        return {
            profileIds: [doctorId, patientProfileId],
            description: 'Conference description'
        };
    }

    public createTanAppointment(dto: CreateTanAppointmentDTO): Observable<CreateTanAppointmentDTO> {
        const requestData: CreateTanConferenceRequest = this.prepareCreateAppointmentRequest(dto);
        return this.http.post(this.createTanAppointmentEndpoint, requestData).pipe(switchMap(result => {
            if (result === 'null' || result === null || !result) {
                return _throw(new Error('Wrong response object by tan appointment creation'));
            }

            const response = <Appointment>result;
            dto.appointment = response;

            return of(dto);
        }));
    }

    private prepareCreateAppointmentRequest(dto: CreateTanAppointmentDTO): CreateTanAppointmentRequest {
        const doctorId = this.profileSelectService.getCurrentProfileId();
        let appointmentType: AppointmentTypes;

        if (dto.appointmentRequest) {
            appointmentType = AppointmentTypes['bookable_appointment'];
        } else {
            appointmentType = AppointmentTypes['one_time_appointment'];
        }

        return {
            profileIds: [doctorId, dto.getProfileId()],
            conferenceId: dto.getConferenceId(),
            tan: dto.tan,
            appointmentType,
            topic: 'Online Videosprechstunde',
            insuranceNumber: dto.getInsuranceNumber(),
            startDateTime: dto.getAppointmentStartDateTime(),
            endDateTime: dto.getAppointmentEndDateTime(),
            isEbm: dto.isEbm(),
            appointmentMessage: dto.getAppointmentMessage()
        };
    }

    public populateAppointmentTimeData(dto: CreateTanAppointmentDTO): Observable<CreateTanAppointmentDTO> {
        dto.appointment = this.appointmentService.addTimeData(dto.appointment);

        return of(dto);
    }

    public getActionStackByDTO(dto: CreateTanAppointmentDTO): Action[] {
        const conference: Conference = this.mapConferenceDTO(dto);
        const actions: Action[] = [
            new ProfileAddAction(dto.profile),
            new ConferenceAddAction(dto.conference),
            new AddConference({conference}),
            new AppointmentAddAction(dto.appointment),
        ];

        if (dto.appointmentRequest) {
            dto.appointmentRequest.appointmentId = dto.appointment.id;
            dto.appointmentRequest.tan = dto.tan;
            dto.appointmentRequest.profileId = dto.profile.id;
            actions.push(new AppointmentRequestAcceptAction(dto.appointmentRequest));
        } else {
            actions.push(new CreateTanAppointmentCompletedAction(dto));
        }

        return actions;
    }

    private mapConferenceDTO(dto: CreateTanAppointmentDTO): Conference {
        return {
            ...dto.conference,
            userTokens: Object.values(dto.conference.userTokens)
        };
    }
}
