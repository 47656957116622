import { Injectable } from '@angular/core';
import { Profile } from '../../profile/store/profile.entity';
import { ProfileSelectService } from '../../profile/providers/profile-select.service';
import { Institution } from '../../../entities/institution/institution.model';

@Injectable()
export class InstitutionService {
    private currentProfile: Profile = null;

    constructor(private profileSelectService: ProfileSelectService) {
    }

    public hideTabsBasedOnInstitutionName(): boolean {
        this.currentProfile = this.profileSelectService.getCurrentProfile();

        return this.hasInstitutionWithoutTabVisible(this.currentProfile.institution);
    }

    private hasInstitutionWithoutTabVisible(institution: Institution): boolean {
        const institutionsWithoutTabs: Institution[] = [
            Institution.HELIOS
        ];

        return institutionsWithoutTabs.includes(institution);
    }

}
