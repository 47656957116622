import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Conference } from './conference.model';

export enum ConferenceActionTypes {
  LoadConferences = '[Conference] Load Conferences',
  AddConference = '[Conference] Add Conference',
  UpsertConference = '[Conference] Upsert Conference',
  AddConferences = '[Conference] Add Conferences',
  UpsertConferences = '[Conference] Upsert Conferences',
  UpdateConference = '[Conference] Update Conference',
  UpdateConferences = '[Conference] Update Conferences',
  DeleteConference = '[Conference] Delete Conference',
  DeleteConferences = '[Conference] Delete Conferences',
  ClearConferences = '[Conference] Clear Conferences',
  AddToken = '[Conference] Add Token',
  Activate = '[Conference] Activate Conference',
}

export class LoadConferences implements Action {
  readonly type = ConferenceActionTypes.LoadConferences;

  constructor(public payload: { conferences: Conference[] }) {}
}

export class AddConference implements Action {
  readonly type = ConferenceActionTypes.AddConference;

  constructor(public payload: { conference: Conference }) {}
}

export class UpsertConference implements Action {
  readonly type = ConferenceActionTypes.UpsertConference;

  constructor(public payload: { conference: Conference }) {}
}

export class AddConferences implements Action {
  readonly type = ConferenceActionTypes.AddConferences;

  constructor(public payload: { conferences: Conference[] }) {}
}

export class UpsertConferences implements Action {
  readonly type = ConferenceActionTypes.UpsertConferences;

  constructor(public payload: { conferences: Conference[] }) {}
}

export class UpdateConference implements Action {
  readonly type = ConferenceActionTypes.UpdateConference;

  constructor(public payload: { conference: Update<Conference> }) {}
}

export class UpdateConferences implements Action {
  readonly type = ConferenceActionTypes.UpdateConferences;

  constructor(public payload: { conferences: Update<Conference>[] }) {}
}

export class DeleteConference implements Action {
  readonly type = ConferenceActionTypes.DeleteConference;

  constructor(public payload: { id: string }) {}
}

export class DeleteConferences implements Action {
  readonly type = ConferenceActionTypes.DeleteConferences;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearConferences implements Action {
  readonly type = ConferenceActionTypes.ClearConferences;
}

export class AddToken implements Action {
    readonly type = ConferenceActionTypes.AddToken;

    constructor(public payload: { token: string }) {}
}

export class Activate implements Action {
    readonly type = ConferenceActionTypes.Activate;

    constructor(public payload: { id: number }) {}
}

export type ConferenceActions =
 LoadConferences
 | AddConference
 | UpsertConference
 | AddConferences
 | UpsertConferences
 | UpdateConference
 | UpdateConferences
 | DeleteConference
 | DeleteConferences
 | ClearConferences
 | AddToken
 | Activate;
