import { DATE_TIME_FORMAT_EXPORT, TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { GenderType } from '../genders';
import { TimeListItem } from '../../utils/time-picker/time-picker.component';
import { DurationType } from '../durations';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

export interface CreateMultiUserTanAppointmentRequest {
    items: MultiUserTanItemRequest[];
    term: MultiUserTanTerm;
    isEbm: boolean;
    appointment: MultiUserTanAppointmentRequest;
    conference: MultiUserTanConferenceRequest;
}

export interface CreateMultiUserTanAppointmentResponse {
    items: MultiUserTanItemResponse[];
    appointment?: MultiUserTanAppointmentResponse;
    conference?: MultiUserTanConferenceResponse;
}

interface MultiUserTanItemRequest {
    user: CreateTanUser;
    tan: MultiUserTan;
}

export interface MultiUserTanItemResponse {
    profile: CreateTanProfile;
    tan: MultiUserTan;
}

interface CreateTanUser {
    title: string;
    gender: {
        id: string;
    };
    firstName: string;
    lastName: string;
    birthday: string;
}

interface CreateTanProfile {
    id: number;
    type: number | string;
    fullName: string;
    title: string;
    salutation: string;
    firstName?: string;
    lastName?: string;
    imageUrl?: string;
    birthday?: string;
    insuranceNumber?: number | null;
    dataProcessTerm?: boolean;
    institution: string;
}

interface MultiUserTanTerm {
    dataProtectionTerm?: number;
    dataProcessTerm?: number;
    termsAndConditionsPatientTerm?: number;
    cancellationTerm?: number;
}

interface MultiUserTanAppointmentRequest {
    appointmentType: number;
    topic?: string;
    startDateTime: string;
    endDateTime: string;
    appointmentMessage: string;
}

interface MultiUserTanAppointmentResponse {
    id?: number;
    conferenceId?: number;
    topic?: string;
    startDateTime?: string;
    endDateTime?: string;
    appointmentMessage?: string;
}

interface MultiUserTanConferenceRequest {
    description: string;
}

interface MultiUserTanConferenceResponse {
    id: string;
}

interface MultiUserTan {
    tan: string;
    email: string;
    emailMessage?: string;
}

export interface CreateMultiUserTanForm {
    patients: Patient[];
    tanDate: NgbDateStruct;
    tanBeginning: TimeListItem;
    tanDuration: DurationType;
    terminDescription: string;
    message: string;
    isEbmCheckBox: boolean;
}

interface Patient {
    salutation: GenderType;
    firstName: string;
    lastName: string;
    birthday?: Birthday;
    patientEmail?: string;
}

interface Birthday {
    birthDay: string;
    birthMonth: string;
    birthYear: string;
}

export class CreateMultiTanAppointmentDTO implements CreateMultiUserTanAppointmentRequest {
    items: MultiUserTanItemRequest[] = [];
    term: MultiUserTanTerm;
    isEbm: boolean;
    appointment: MultiUserTanAppointmentRequest;
    conference: MultiUserTanConferenceRequest;

    constructor(formValue: CreateMultiUserTanForm, appointmentDate: string) {

        const tanBeginningTime = `${appointmentDate} ${formValue.tanBeginning.timeOnly}`;

        const startDateTime = TimeHelperService.localToUtcMomentDateTime(tanBeginningTime)
            .format(DATE_TIME_FORMAT_EXPORT);

        const endDateTime = TimeHelperService.localToUtcMomentDateTime(tanBeginningTime)
            .add(formValue.tanDuration.id, 'm')
            .format(DATE_TIME_FORMAT_EXPORT);

        this.appointment = {
            appointmentType: 1,
            topic: 'Online Videosprechstunde',
            startDateTime,
            endDateTime,
            appointmentMessage: formValue.message
        };

        this.term = {};
        this.isEbm = formValue.isEbmCheckBox;

        this.conference = {
            description: formValue.terminDescription
        };

        formValue.patients.forEach((patient: Patient) => {
            const item: MultiUserTanItemRequest = {
                user: new CreateTanUserDto(patient),
                tan: {
                    tan: 'TAN',
                    email: patient.patientEmail,
                    emailMessage: formValue.message
                }
            };
            this.items.push(item);
        });
    }
}

class CreateTanUserDto implements CreateTanUser {
    title: string;
    gender: {
        id: string;
    };
    firstName: string;
    lastName: string;
    birthday: string;

    constructor(patient: Patient) {
        this.title = '';
        this.firstName = patient.firstName;
        this.lastName = patient.lastName;
        this.gender = {
            id: patient.salutation.id
        };
        this.birthday = '';
        if (!isBirthdayEmpty(patient.birthday)) {
            this.birthday = `${patient.birthday.birthYear}-${patient.birthday.birthMonth}-${patient.birthday.birthDay}`;
        }
    }
}

function isBirthdayEmpty(birthday: Birthday): boolean {
    return (birthday.birthDay === '') || (birthday.birthMonth === '') && (birthday.birthYear === '');
}

