<div class="consultation-hour-item card blue-card no-shadow"
     [class.getsNotModified]="!getsModified">
    <div *ngIf="getsModified;then modifyView else noModifyView">
    </div>
    <ng-template #modifyView>
        <app-product-form
                [prefillTitle]="product.title"
                [productId]="product.id"
                [prefillDuration]="product.duration"
                [prefillType]="product.productType"
                [prefillDescription]="product.description"
                [prefillPrice]="product.productPriceItems"
                [prefillVanishTime]="product.vanishTime"
                [showCancel]="true"
                (onCancel)="getsModified = false;"
                [showDelete]="true"
                (onDelete)="deleteProduct()"
                [showSave]="true"
                [showCreate]="false"
                (onSubmit)="updateProduct($event)">
        </app-product-form>
    </ng-template>
    <ng-template #noModifyView>
        <div class="no-modify-view" (click)="getsModified = true;">
            <div class="feedback-checkmark">
                <i class="fa fa-check"></i>
            </div>
            <div class="modify-icon">
                <i class="fas fa-pen-alt"></i><i class="fal fa-ellipsis-h pull-down"></i>
            </div>
            <h5>{{ product.title | uppercase }}</h5>
            <p>{{product.description}}</p>
            <div class="info-line">
                <span class="keyword">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.TYPE' | translate }}: </span><span>{{ getShortType(product.productType) }}</span>
                <span> | </span>
                <span class="keyword">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.PRICE' | translate }}: </span><span>{{ getTotalSum(product.productPriceItems) | number:'1.2-2' | noComma | commaSeperated}} €</span>
                <span> | </span>
                <span class="keyword">{{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.DURATION' | translate }}: </span><span> {{ product.duration }} {{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.DURATION-UNIT' | translate }}</span>
            </div>
        </div>
    </ng-template>
    <div class="confirm-delete" *ngIf="showConfirmDelete">
        <div class="center-wrapper">
            <p>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.CONFIRM-DELETE-TEXT'
                | translate:{ productTitle:product.title} }}
            </p>
            <div class="button-row">
                <button class="btn btn-link"
                        (click)="showConfirmDelete = false;">{{
                    'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.CONFIRM-DELETE-CANCEL' | translate }}
                </button>
                <button class="btn btn-danger"
                        (click)="deleteProductConfirmed()">
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.CONFIRM-DELETE-DELETE' | translate }}
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="confirm-delete confirm-delete-last" *ngIf="showConfirmDeleteLastProduct">
        <div class="center-wrapper">
            <p>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.CONFIRM-DELETE-LAST-TEXT'
                | translate:{ productTitle:product.title} }}
            </p>
            <div class="button-row">
                <button class="btn btn-primary"
                        (click)="showConfirmDeleteLastProduct = false;">{{
                    'APPOINTMENT.CREATE-CONSULTATION.LIST-ITEM.CONFIRM-DELETE-LAST-CANCEL' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
