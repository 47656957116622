import { DATE_TIME_FORMAT_EXPORT, TimeHelperService } from '../../../utils/time-helpers/time-helper.service';
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppointmentRequestFormBuilder } from './appointment-request.form-builder';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentRequest, EntityStateAppointmentRequest } from '../../store/appointment-request.entity';
import { Store } from '@ngrx/store';
import { APPOINTMENT_OVERVIEW_HISTORY, APPOINTMENT_OVERVIEW_UPCOMING } from '../../../../routes';
import { AppointmentRequestRejectAction } from '../../store/appointment-request.action';
import { getGenderIdByName } from '../../../utils/genders';
import { AppointmentCreateTanAction } from '../../../appointment/store/one-time-appointment/appointment.action';
import { CreateTanAppointmentDTO, CreateTanFormValues } from '../../../appointment/providers/create-tan.types';

@Component({
    selector: 'app-appointment-request',
    templateUrl: './appointment-request.component.html',
    styleUrls: ['./appointment-request.component.scss']
})
export class AppointmentRequestComponent {

    @Input() appointmentRequest: AppointmentRequest;

    public randomIdentifier: string;
    public acceptOrDecline: string;
    public acceptingForm: UntypedFormGroup;
    public rejectionForm: UntypedFormGroup;

    public upcomingLink = APPOINTMENT_OVERVIEW_UPCOMING;
    public historyLink = APPOINTMENT_OVERVIEW_HISTORY;

    public status: 'open' | 'accepted' | 'rejected';

    constructor(private formBuilder: UntypedFormBuilder,
                private translate: TranslateService,
                private store: Store<EntityStateAppointmentRequest>) {
        this.status = 'open';

        this.acceptingForm = AppointmentRequestFormBuilder.buildAcceptingForm(this.formBuilder);
        this.rejectionForm = AppointmentRequestFormBuilder.buildRejectingForm(this.formBuilder);

        this.randomIdentifier = Math.random().toString(36).substring(7);
    }

    onDecline() {
        this.status = 'rejected';
        this.appointmentRequest.confirmationComment = this.rejectionForm.controls['confirmation_comment'].value;
        this.store.dispatch(new AppointmentRequestRejectAction(this.appointmentRequest));
    }

    showPersonalInfo(): boolean {
        return !!this.appointmentRequest && (
            !!this.appointmentRequest.email ||
            !!this.appointmentRequest.insuranceNumber ||
            !!this.appointmentRequest.appointmentMessage
        );
    }

    public onAccept() {
        this.status = 'accepted';
        this.appointmentRequest.confirmationComment = this.acceptingForm.controls['confirmation_comment'].value;
        this.store.dispatch(new AppointmentCreateTanAction(this.createPayloadForAccept()));
    }

    private createPayloadForAccept(): CreateTanAppointmentDTO {
        const startDateTime = TimeHelperService.localToUtcMomentDateTime(this.appointmentRequest.appointmentStartDateTime)
            .format(DATE_TIME_FORMAT_EXPORT);

        const endDateTime = TimeHelperService.localToUtcMomentDateTime(this.appointmentRequest.appointmentStartDateTime)
            .add(this.appointmentRequest.appointmentDuration, 'm')
            .format(DATE_TIME_FORMAT_EXPORT);

        const payload: CreateTanFormValues = {
            user: {
                title: '',
                firstName: this.appointmentRequest.firstName,
                surname: this.appointmentRequest.lastName,
                gender: {
                    id: getGenderIdByName(this.appointmentRequest.salutation)
                },
                insuranceNumber: this.appointmentRequest.insuranceNumber
            },
            tan: {
                validFrom: startDateTime,
                validTo: endDateTime,
                email: this.appointmentRequest.email
            },
            term: {
                dataProtectionTerm: this.appointmentRequest.dataProtectionTerm,
                dataProcessTerm: this.appointmentRequest.dataProcessTerm,
                termsAndConditionsPatientTerm: this.appointmentRequest.termsAndConditionsPatientTerm,
                cancellationTerm: this.appointmentRequest.cancellationTerm
            },
            isEbm: false,
            appointmentMessage: this.appointmentRequest.appointmentMessage
        };

        const dto = new CreateTanAppointmentDTO(payload);
        dto.appointmentRequest = this.appointmentRequest;

        return dto;
    }
}
