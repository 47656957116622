<div class='appointment-failed-wrapper'>
    <div class='appointment-failed-container'>
        <div id='check'>
            <span class="fal fa-exclamation-triangle fa-5x" aria-hidden="true"></span>
        </div>
        <div id='header'>{{ 'APPOINTMENT.TAN-CREATE-FAILED.HEADER' | translate }}</div>
        <div id='footer-text'>{{ 'APPOINTMENT.TAN-CREATE-FAILED.FOOTER-TEXT' | translate }}</div>
        <div id='footer-buttons'>
            <button type="button"
                    id='gotoWaitingRoomButton'
                    (click)='goAppointmentUpcoming()'
                    class="btn btn-link">{{ 'APPOINTMENT.TAN-CREATE-FAILED.GO-TO-WAITINGROOM-BUTTON-TEXT' | translate }}</button>
            <button type="button"
                    id='createNewAppointmentButton'
                    (click)='goToCreateAppointment()'
                    class="btn btn-primary">{{ 'APPOINTMENT.TAN-CREATE-FAILED.GO-TO-TAN-CREATE-BUTTON-TEXT' | translate }}
            </button>
        </div>
    </div>
</div>
