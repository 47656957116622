import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Product } from '../product.model';
import { AddProduct, DeleteProduct, LoadProducts, UpdateProduct } from '../store/product.actions';
import * as fromProduct from '../store/product.reducer';
import { Store } from '@ngrx/store';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { ProductMapper } from './dto/product-mapper';
import { ProductDto } from './dto/product-dto';
import { Observable, throwError } from 'rxjs';
import { ProductState } from '../store/product.types';

@Injectable()
export class ProductService {

    private appointmentProductsEndpoint = environment.appointmentsProductsEndpoint;

    constructor(private http: HttpClient,
                private store: Store<ProductState>) {
    }

    public addProductInStore(product: Product): void {
        this.store.dispatch(new AddProduct({product}));
    }

    public addAllProductsInStore(products: Product[]): void {
        this.store.dispatch(new LoadProducts({products}));
    }

    public updateProductInStore(product: Product): void {
        this.store.dispatch(new UpdateProduct({product: {id: product.id, changes: product}}));
    }

    public deleteProductInStore(product: Product): void {
        this.store.dispatch(new DeleteProduct({id: product.id}));
    }

    public getAllProductsFromStore(): Observable<Product[]> {
        return this.store.select(fromProduct.selectAll);
    }

    public getProductCountFromStore(): Observable<number> {
        return this.getAllProductsFromStore().pipe(map(productlist => productlist.length));
    }

    public getProductFromeStore(id: number): Observable<Product> {
        return this.store.select(fromProduct.getProductByIdSelector(id));
    }

    public loadAllProducts(): Observable<Product[]> {
        return this.http
            .get(this.appointmentProductsEndpoint, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map((productDtoList: ProductDto[]): Product[] => {
                    const products: Product[] = [];
                    productDtoList.forEach((productDto: ProductDto) => {
                        products.push(ProductMapper.mapDtoToEntity(productDto));
                    });
                    this.addAllProductsInStore(products);
                    return products;
                }),
                catchError(this.handleError)
            );
    }

    public createProduct(product: Product): Observable<void> {
        const dto = ProductMapper.mapEntityToDto(product);
        return this.http
            .post(this.appointmentProductsEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map((responseDto: ProductDto): void => {
                    product.id = responseDto.productId;
                    return this.addProductInStore(product);
                }),
                catchError(this.handleError)
            );
    }

    public updateProduct(product: Product): Observable<void> {
        const dto = ProductMapper.mapEntityToDto(product);
        return this.http
            .put(this.appointmentProductsEndpoint, dto, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map((): void => this.updateProductInStore(product)),
                catchError(this.handleError)
            );
    }

    public deleteProduct(product: Product): Observable<void> {
        return this.http
            .delete(
                this.appointmentProductsEndpoint + '/' + product.id,
                {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                map((): void => this.deleteProductInStore(product)),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in ProductService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
