import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppointmentInfoType } from '../appointment-info/appointment-info';
import { MultiUserAppointmentService } from '../../providers/multi-user-appointment.service';
import { ProfileService } from '../../../profile/providers/profile.service';
import {
    MultiUserAppointment,
    MultiUserAppointmentRequestType,
    Pagination
} from '../../store/appointment/multi-user-appointment.entity';

@Component({
    selector: 'app-canceled-tab',
    templateUrl: './canceled-tab.component.html',
    styleUrls: [
        './canceled-tab.component.scss',
        '../appointment-overview/multi-user-appointment-overview/multi-user-appointment-overview.component.scss'
    ]
})
export class CanceledTabComponent {
    public isInit$: Observable<boolean>;
    public canceledAppointments$: Observable<MultiUserAppointment[]>;
    public canceledAppointmentInfoType = AppointmentInfoType.CANCELED;
    public pagination$: Observable<Pagination>;
    public isProfileInit$: Observable<boolean>;
    @ViewChild('itemsRef') itemsRef: ElementRef;

    constructor(private appointmentService: MultiUserAppointmentService,
                private profileService: ProfileService) {
        this.appointmentService.getAppointments(MultiUserAppointmentRequestType.CANCELED);
        this.isInit$ = appointmentService.isInit();
        this.canceledAppointments$ = appointmentService.getAllMultiUserAppointments$();
        this.pagination$ = this.appointmentService.getPagination$();
        this.isProfileInit$ = this.profileService.isInit$();
    }

    public onPageChange(pageNumber: number) {
        const position = this.itemsRef.nativeElement.offsetTop;
        this.appointmentService.getAppointments(
            MultiUserAppointmentRequestType.CANCELED,
            {pageNumber: pageNumber.toString()},
            position
        );
    }
}
