import { createFeatureSelector } from '@ngrx/store';
import { appointmentRequestAdapter, EntityStateAppointmentRequest } from './appointment-request.entity';

export const selectAppointmenteRequestState = createFeatureSelector<EntityStateAppointmentRequest>('appointmentRequest');

export const {
    selectIds: idList,
    selectEntities: allAppointmentRequests,
    selectAll: selectAllAppointmentRequests,
    selectTotal: totalAppointments
} = appointmentRequestAdapter.getSelectors(selectAppointmenteRequestState);
