import { Observable } from 'rxjs/internal/Observable';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs';

const getErrorMessage = (maxRetry: number) =>
    `Tried to load resource over the XHR for ${maxRetry} times without success. Giving up :(`;

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_DELAY_MS = 1000;

export function delayedRetry(delayMs = DEFAULT_DELAY_MS, maxRetry = DEFAULT_MAX_RETRIES) {
    let retries = maxRetry;

    return (src: Observable<any>) =>
        src.pipe(
            retryWhen((errors: Observable<any>) => errors.pipe(
                delay(delayMs),
                mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry)))
            ))
        );
}
