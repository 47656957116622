import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AppointmentService } from '../../providers/appointment.service';
import {
    APPOINTMENT_CONFERENCE_CREATE,
    APPOINTMENT_CREATE,
    APPOINTMENT_CREATE_TAN,
    APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED,
    APPOINTMENT_CREATE_TAN_APPOINTMENT_FAILED,
    AppointmentConferenceCreateAction,
    AppointmentCreateAction,
    AppointmentCreateTanAction, AppointmentsInitialReceivedFailedAction, AppointmentsInitialReceivedSuccessfulAction,
    AppointmentsRemoveAllAction,
    CreateTanAppointmentCompletedAction,
    CreateTanAppointmentFailedAction
} from './appointment.action';
import { Appointment } from './appointment.entity';
import { APP_CHANGE_TO_ANONYMOUS_STATE, APP_CHANGE_TO_AUTHENTICATED_STATE } from '../../../../providers/store/app.action';
import { CreateTanService } from '../../providers/create-tan.service';
import { APPOINTMENT_CREATE_FAILURE, APPOINTMENT_CREATE_SUCCESS } from '../../../../routes';
import { CreateTanAppointmentDTO } from '../../providers/create-tan.types';
import { RouterHelperService } from '../../../utils/router-helper/router-helper.service';
import { LogAppointmentService } from '../../../logging/providers/log-appointment.service';
import { FeatureFlagService } from '../../../feature-flag/providers/feature-flag.service';
import { FeatureFlag } from '../../../feature-flag/feature-flag.types';

@Injectable()
export class AppointmentEffects {
    constructor(private actions$: Actions,
                private appointmentService: AppointmentService,
                private createTanService: CreateTanService,
                private router: RouterHelperService,
                private trace: LogAppointmentService,
                private featureFlag: FeatureFlagService) {
    }

     doRequestAppointments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_AUTHENTICATED_STATE),
        filter(() => !this.featureFlag.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL)),
        switchMap(() => this.appointmentService.getAppointments().pipe(
            mergeMap((appointments: Appointment[]) => this.appointmentService.populateTimeData(appointments)),
            map((appointments: Appointment[]) => new AppointmentsInitialReceivedSuccessfulAction(appointments)),
            catchError((err) => of(new AppointmentsInitialReceivedFailedAction(err)))))));

     doCreateTan$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CREATE_TAN),
        switchMap((action: AppointmentCreateTanAction) => this.createTanService
            .createTan(action.payload).pipe(
                map((dto: CreateTanAppointmentDTO) => new AppointmentConferenceCreateAction(dto)),
                catchError((err) => of(new CreateTanAppointmentFailedAction(err)))))));

     doCreateTanConference$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CONFERENCE_CREATE),
        map((action: AppointmentConferenceCreateAction) => action.payload),
        switchMap((dto: CreateTanAppointmentDTO) => this.createTanService
            .createTanConference(dto).pipe(
                map((tanDto: CreateTanAppointmentDTO) => new AppointmentCreateAction(tanDto)),
                catchError((err) => of(new CreateTanAppointmentFailedAction(err)))))));

     doCreateTanAppointment$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CREATE),
        switchMap((action: AppointmentCreateAction) => this.createTanService
            .createTanAppointment(action.payload).pipe(
                mergeMap((dto: CreateTanAppointmentDTO) => this.createTanService.populateAppointmentTimeData(dto)),
                map((dto: CreateTanAppointmentDTO) => this.createTanService.getActionStackByDTO(dto)),
                mergeMap((actions: Action[]) => actions),
                catchError((err) => of(new CreateTanAppointmentFailedAction(err)))))));

     doRemoveAllAppointments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        filter(() => !this.featureFlag.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL)),
        map(() => new AppointmentsRemoveAllAction())
    ));

     doRedirectToSuccessPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED),
        tap((action: CreateTanAppointmentCompletedAction) => {
            let isEmailExists = false;
            if (action.payload.profile.email) {
                isEmailExists = true;
            }
            this.router.navigate([APPOINTMENT_CREATE_SUCCESS], {queryParams: {emailsent: isEmailExists}});
        })), {dispatch: false});

     logSuccessPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CREATE_TAN_APPOINTMENT_COMPLETED),
        tap((action: CreateTanAppointmentCompletedAction) => this.trace.logAppointmentCreated(action.payload.appointment.id))), {dispatch: false});

     doRedirectToFailedPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_CREATE_TAN_APPOINTMENT_FAILED),
        tap(() => {
            this.router.navigate([APPOINTMENT_CREATE_FAILURE]);
        })), {dispatch: false});
}
