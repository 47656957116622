import {
    AppointmentRequest,
    appointmentRequestAdapter, EntityStateAppointmentRequest,
    initialAppointmentRequestListState
} from './appointment-request.entity';
import {
    AppointmentRequstAction,
    APPOINTMENTS_REQUEST_RECEIVED_SUCCESSFUL,
    APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL,
    AppointmentRequestActionType,
    APPOINTMENT_REQUEST_REJECT_SUCCESSFUL
} from './appointment-request.action';

export function appointmentRequestReducer(state: EntityStateAppointmentRequest = initialAppointmentRequestListState,
    action: AppointmentRequstAction): EntityStateAppointmentRequest {
    switch (action.type) {
        case AppointmentRequestActionType.ADD_APPOINTMENT_REQUESTS: {
            return {
                ...appointmentRequestAdapter.addMany(<AppointmentRequest[]>action.payload, state),
                isInit: false,
            };
        }
        case AppointmentRequestActionType.DELETE_ONE: {
            return {
                ...appointmentRequestAdapter.removeOne(<number>action.payload, state),
                isInit: false,
            };
        }
        default: {
            return state;
        }
    }
}
