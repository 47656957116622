<div class="flex-form-row">
    <div class="form-group flex-fix-2">
        <label for="start">
            <span class='required'>*</span>
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.START-LABEL' | translate }}
            <span class='fal fa-question-circle'
                  aria-hidden="true"
                  placement="bottom"
                  ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.START-HELP' | translate }}"></span>
        </label>
        <app-time-picker id="start"
                         [(selectedTime)]="startTimeObject"
                         class='time-picker'
                         ngDefaultControl
                         (selectedTimeEmitter)="setStartTime($event)">
        </app-time-picker>
    </div>
    <div class="flex-fix form-icon-wrapper">
        <i class="fal fa-minus"></i>
    </div>
    <div class="form-group flex-fix-2">
        <label for="end">
            <span class='required'>*</span>
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.END-LABEL' | translate }}
            <span class='fal fa-question-circle'
                  aria-hidden="true"
                  placement="bottom"
                  ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.END-HELP' | translate }}"></span>
        </label>
        <app-time-picker id="end"
                         #end
                         [selectedTime]="endTimeObject"
                         class='time-picker'
                         ngDefaultControl
                         [required]="true"
                         [disabledBelow]="startTimeObject"
                         (selectedTimeEmitter)="setEndTime($event)">
        </app-time-picker>
    </div>
    <div class="flex-fix form-icon-wrapper">
        <i class="fal fa-angle-right"></i>
    </div>
    <div class="form-group flex-grow-2">
        <label for="products">
            <span class='required'>*</span>
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.PRODUCTS-LABEL' | translate }}
            <span class='fal fa-question-circle'
                  aria-hidden="true"
                  placement="bottom"
                  ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.PRODUCTS-HELP' | translate }}"></span>
        </label>
        <tag-input
            id="products"
            #products
            [(ngModel)]="selectedProducts"
            [identifyBy]="'id'"
            [displayBy]="'title'"
            theme="'minimal'"
            required
            [ripple]="false"
            [placeholder]="''"
            [secondaryPlaceholder]="''"
            [onlyFromAutocomplete]="true"
            [inputClass]="'tagInputFormInput'"
            (click)="inputFormClickHandler($event)">
            <tag-input-dropdown
                [showDropdownIfEmpty]="true"
                [autocompleteItems]="allProducts"
                [identifyBy]="'id'"
                [displayBy]="'title'">
            </tag-input-dropdown>
            <ng-template let-item="item" let-index="index">
                <span>{{ item.title }}</span>
                <delete-icon (click)="products.removeItem(item, index)"></delete-icon>
                <div [class.to-short-for-product]="isProductTooLongForTimespan(item)"
                     placement="bottom"
                     ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.PRODUCT-TOO-LONG-TOOLTIP' | translate }}">
                    <div class="white-overlay"></div>
                </div>
            </ng-template>
        </tag-input>
    </div>
</div>
