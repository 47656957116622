import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { Durations, DurationType } from '../../../durations';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { ProductService } from '../../../appointment-type/product/providers/product.service';
import { Product, ProductPriceItem, ProductType } from '../../../appointment-type/product/product.model';

@Component({
    selector: 'app-product-form',
    templateUrl: './product-form.component.html',
    styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

    @Input() prefillTitle: string;
    @Input() prefillDescription: string;
    @Input() prefillDuration: string;
    @Input() prefillType: string;
    @Input() productId: number;
    @Input() prefillPrice: ProductPriceItem[];
    @Input() prefillVanishTime: number;

    @Input() showCancel = false;
    @Input() showDelete = false;
    @Input() showCreate = true;
    @Input() showSave = false;

    @Output() onCancel = new EventEmitter<void>();
    @Output() onDelete = new EventEmitter<void>();

    @Output() onSubmit = new EventEmitter<Product>();

    @ViewChild('consultatonHourForm')
    public consultatonHourForm: NgForm;

    public vanishTime = {hours: 0, minutes: 0};

    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;

    private productPriceItemCount = 1;
    productPriceItemIds: number[] = [1];

    public durations: DurationType[] = [];
    public selectedDuration: DurationType;

    public expanded = false;

    constructor(private productService: ProductService) {
        this.durations = Durations;
    }

    ngOnInit() {
        this.prefillForm();
    }

    onFormSubmit() {
        if (this.consultatonHourForm.valid) {
            this.consultatonHourForm.value.productId = this.productId;
            this.onSubmit.emit(this.mapFormValuesToProduct(this.consultatonHourForm.value));
        } else {
            Object.keys(this.consultatonHourForm.controls).forEach(key => {
                this.consultatonHourForm.controls[key].markAsTouched({onlySelf: true});
            });
            if (this.consultatonHourForm.controls.productPriceItems) {
                Object.keys((<UntypedFormGroup>this.consultatonHourForm.controls.productPriceItems).controls).forEach(key => {
                    (<UntypedFormGroup>this.consultatonHourForm.controls.productPriceItems).controls[key].markAsTouched({onlySelf: true});
                });
            }
            this.invalidDirectives.map((directive) => {
                directive.triggerTooltip();
            });
        }
    }

    private prefillForm(): void {
        setTimeout(() => {
            if (Object.keys(this.consultatonHourForm.controls).length === 0) {
                return;
            }
            this.consultatonHourForm.controls['title'].setValue(this.prefillTitle);
            this.consultatonHourForm.controls['description'].setValue(this.prefillDescription);
            this.consultatonHourForm.controls['type'].setValue(this.prefillType);
            if (!!this.prefillDuration) {
                this.selectedDuration = this.findDurationById(this.prefillDuration);
            } else {
                this.selectedDuration = {id: '05', name: '05 min'};
            }

            if (this.prefillTitle !== ProductType.NOT_FREE && this.prefillPrice) {
                this.prefillPriceItemFrom(this.prefillPrice);
            }

            if (this.prefillVanishTime) {
                this.vanishTime.hours += (this.prefillVanishTime / 60) - (this.prefillVanishTime % 60 / 60);
                this.vanishTime.minutes = this.prefillVanishTime % 60;
            }
        });
    }

    private prefillPriceItemFrom(priceArray: ProductPriceItem[]): void {
        priceArray.forEach((priceLine, i) => {
            i += 1;
            if (i > 2) {
                this.addProductPriceItem(true);
            }
            setTimeout(() => {
                const priceItems = (<UntypedFormGroup>this.consultatonHourForm.controls.productPriceItems);
                if (priceItems) {
                    priceItems.controls['billingCode[' + i + ']'].setValue(priceLine.billingCode);
                    priceItems.controls['paymentValue[' + i + ']'].setValue(priceLine.paymentValue);
                    priceItems.controls['multiplicator[' + i + ']'].setValue(priceLine.multiplicator);
                }
            });
        });
    }

    private findDurationById(id: string): DurationType {
        return this.durations.find((duration: DurationType) => (duration.id === id.toString()));
    }

    public removeProductPriceItem(i: number) {
        this.productPriceItemIds.splice(i, 1);
    }

    public addProductPriceItem(forceAdd: boolean = false): void {
        if (this.areProductPriceItemsValid() || forceAdd) {
            this.productPriceItemIds.push(++this.productPriceItemCount);
        }
    }

    public areProductPriceItemsValid(): boolean {
        const priceItems = (<UntypedFormGroup>this.consultatonHourForm.controls.productPriceItems);
        if (priceItems) {
            return priceItems.valid;
        } else {
            return true;
        }
    }

    public getLineItemSum(index: number, source = this.consultatonHourForm.value): number {
        if (!!source.productPriceItems) {
            const val = source.productPriceItems['paymentValue[' + index + ']'];
            const up = source.productPriceItems['multiplicator[' + index + ']'];

            return val * up;
        } else {
            return 0;
        }
    }

    public getTotalSum(priceList: number[] = this.productPriceItemIds): number {
        return priceList.reduce((last, current) => last + this.getLineItemSum(current), 0);
    }

    public compareByItem(item1: { id: number }, item2: { id: number }): boolean {
        if (item1 && item2) {
            return item1.id === item2.id;
        } else {
            return false;
        }
    }

    public isDefaultDurationSelected(): boolean {
        if (typeof this.selectedDuration === 'undefined' || !this.selectedDuration) {
            return false;
        } else {
            return (this.selectedDuration.id === '0');
        }
    }

    private mapFormValuesToProduct(obj: any): Product {
        const product = <Product>{
            title: obj.title,
            description: obj.description,
            duration: obj.duration.id,
            productType: obj.type,
            productPriceItems: [],
            vanishTime: this.vanishTimeInMinutes
        };

        if (obj.productId !== '' && obj.productId !== undefined) {
            product.id = Number(obj.productId);
        }

        const productPriceItemLines = [];
        if (product.productType === ProductType.NOT_FREE) {
            for (let i = 0; i < this.productPriceItemIds.length; i++) {
                const productPriceLineItem: ProductPriceItem = {
                    billingCode: obj.productPriceItems['billingCode[' + this.productPriceItemIds[i] + ']'],
                    paymentValue: obj.productPriceItems['paymentValue[' + this.productPriceItemIds[i] + ']'],
                    multiplicator: obj.productPriceItems['multiplicator[' + this.productPriceItemIds[i] + ']'],
                    sum: this.getLineItemSum(this.productPriceItemIds[i], obj)
                };
                productPriceItemLines.push(productPriceLineItem);
            }
        }
        product.productPriceItems = productPriceItemLines;

        return product;
    }

    public updateVanishTimeMinutes(minutes: number): void {
        if (minutes < 0) {
            this.vanishTime.minutes = 0;
            return;
        }
        if (minutes >= 60) {
            this.vanishTime.hours += (minutes / 60) - (minutes % 60 / 60);
            this.vanishTime.minutes = minutes % 60;
        } else {
            this.vanishTime.minutes = minutes;
        }
    }

    get vanishTimeInMinutes(): number {
        return Number(this.vanishTime.minutes) + Number(this.vanishTime.hours) * 60;
    }
}
