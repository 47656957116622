<div class="flex-form-row" *ngFor="let timeFrameId of timeFrameIds; let i=index;">
    <div class="flex-grow">
        <app-form-line
                [allProducts]="allProducts"
                [weekDay]="weekDay"
                [prefillConsultationHour]="consultationHourList[timeFrameId-1]"
        >
        </app-form-line>
    </div>
    <div class="flex-fix clearfix">
        <button class="btn btn-round btn-primary button-in-row"
                (click)="removeTimeFrameItem(i)"><i
                class="fa fa-minus"></i>
        </button>
    </div>
</div>
<div class="add-timeframe-button-wrapper clearfix">
    <button class="btn btn-round btn-primary add-timeframe-button float-right"
            (click)="addTimeFrameItem()">
        <i class="fa fa-plus"></i>
    </button>
    <span class="float-right bold"
          (click)="addTimeFrameItem()">
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.ADD-TIMEFRAME-THIS-DAY' | translate }}
    </span>
</div>
<div class="error-space clearfix" *ngIf="anErrorOccurred">
    <span class="bold"><i class="fas fa-exclamation-triangle"></i> {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.ERROR.TITLE' | translate }}</span>
    <p>{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.ERROR.TEXT' | translate }}</p>
</div>
<div class="card-footer clearfix">
    <div class="footer-left">
        <span class='required'>*</span>{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.REQUIRED' | translate }}
    </div>
    <div class="footer-right">
        <button id="cancelButton"
                class="btn btn-link color-white"
                (click)="onCancel.emit()">
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.CANCEL-BTN' | translate }}
        </button>
        <button type="submit"
                id='submitSaveButton'
                class="btn btn-success"
                [disabled]="!areAllFormLinesValid()"
                (click)="onFormSubmit()">
            {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.SUBMIT-BTN' | translate }}
            <span class='fal fa-check-circle' aria-hidden="true"></span>
        </button>
    </div>
</div>
