<div class="overflow-wrapper">
    <div class="what-is-heading history">
        <div class="content-wrapper">
            <div class="illustration-text-wrapper">
                <h2>{{ 'APPOINTMENT-OVERVIEW.HISTORY.WHAT-IS' | translate}}</h2>
                <p>{{ 'APPOINTMENT-OVERVIEW.HISTORY.WHAT-IS-DESCRIPTION' | translate}}</p>
            </div>
        </div>
        <img class="header-illustration" src="/assets/img/illustrations/history.png" alt="">
    </div>
</div>
<ng-container *ngIf="{
        history: historyAppointments$ | async,
        isAppointmentInit: isInit$ | async,
        isProfileInit: isProfileInit$ | async
    } as appointments">
    <div class="content-wrapper mt-4"  *ngIf="appointments.isAppointmentInit || appointments.isProfileInit">
        <img src="/assets/img/spinner/loader.gif" class="loader-spinner" />
        <span>&nbsp;{{ 'APPOINTMENT-OVERVIEW.APPOINTMENTS-LOADING' | translate }}</span>
    </div>
    <div class="appointment-info-wrapper" *ngIf="appointments?.history?.length > 0 && !appointments.isProfileInit">
        <div class="content-wrapper mt-4" #itemsRef>
            <app-multi-user-appointment-info
                *ngFor="let appointment of appointments?.history"
                [appointment]="appointment"
                [appointmentInfoType]="HISTORY_APPOINTMENT_INFO_TYPE">
            </app-multi-user-appointment-info>
        </div>
    </div>
    <div *ngIf="!appointments?.history?.length && !appointments.isAppointmentInit && !appointments.isProfileInit"
         class="content-wrapper empty-appointments appointment-overview-wrapper">
        <h3 class="no-appointment-message">{{ 'APPOINTMENT-OVERVIEW.HISTORY.EMPTY-APPOINTMENTS' | translate }}</h3>
    </div>
    <div *ngIf="appointments.history?.length && !appointments.isAppointmentInit && !appointments.isProfileInit">
        <ngb-pagination *ngIf="(pagination$ | async) as pagination"
                        class="d-flex justify-content-center mt-4"
                        [collectionSize]="pagination?.totalCount"
                        [pageSize]="pagination?.pageSize"
                        [page]="pagination?.pageNumber"
                        [boundaryLinks]="true"
                        (pageChange)="onPageChange($event)">
        </ngb-pagination>
    </div>
</ng-container>
