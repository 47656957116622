import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionService } from './providers/institution.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        InstitutionService
    ]
})
export class InstitutionSettingsModule {
}
