import {AppointmentRequest} from '../store/appointment-request.entity';
import {DATE_TIME_FORMAT_FRONTEND, TimeHelperService} from '../../utils/time-helpers/time-helper.service';
import {getGenderNameById} from '../../utils/genders';
import {RawAppointmentRequest} from './appointment-request.types';

export class MapService {

    public static convertToAppointmentRequest(raw: RawAppointmentRequest): AppointmentRequest {
        if (raw.salutation !== null || raw.salutation !== undefined) {
            raw.salutation = raw.salutation.trim();
        }

        const localAppointmentStartDateTime = TimeHelperService
            .utcToLocalMomentDateTime(raw.appointmentStartDateTime)
            .format(DATE_TIME_FORMAT_FRONTEND);

        const localAppointmentCreatedAt = TimeHelperService
            .utcToLocalMomentDateTime(raw.appointmentCreatedAt)
            .format(DATE_TIME_FORMAT_FRONTEND);

        return <AppointmentRequest>{
            ...raw,
            id: raw.appointmentRequestId,
            fullName: getGenderNameById(raw.salutation) + ' ' + raw.firstName + ' ' + raw.lastName,
            appointmentStartDateTime: localAppointmentStartDateTime,
            appointmentCreatedAt: localAppointmentCreatedAt,
        };
    }
}
