import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityStateMultiUserAppointment, MultiUserAppointment, multiUserAppointmentAdapter } from './multi-user-appointment.entity';


export const selectMultiUserAppointmentState = createFeatureSelector<EntityStateMultiUserAppointment>('multiUserAppointment');

export const {
    selectIds: idList,
    selectEntities: allAppointments,
    selectAll: selectAll,
    selectTotal: totalAppointments
} = multiUserAppointmentAdapter.getSelectors(selectMultiUserAppointmentState);

export const isInitSelector = (state: EntityStateMultiUserAppointment) => state.isInit;
export const paginationSelector = (state: EntityStateMultiUserAppointment) => state.pagination;
export const tanAppointmentIdSelector = (state: EntityStateMultiUserAppointment) => state.tanAppointmentId;

export const getIsInitSelector = createSelector(selectMultiUserAppointmentState, isInitSelector);
export const getPaginationSelector = createSelector(selectMultiUserAppointmentState, paginationSelector);
export const getTanAppointmentIdSelectorSelector = createSelector(selectMultiUserAppointmentState, tanAppointmentIdSelector);

export const selectCreatedMultiUserAppointment = createSelector(
    allAppointments,
    getTanAppointmentIdSelectorSelector,
    (allEntities, selected) => allEntities[selected]
);

export function selectByConferenceId(conferenceId: number): MemoizedSelector<EntityStateMultiUserAppointment, MultiUserAppointment> {
    const appointmentEntityByConferenceID = (state: EntityStateMultiUserAppointment) => {
        for (const appointmentIds in state.entities) {
            if (state.entities[appointmentIds].conferenceId === conferenceId) {
                return state.entities[appointmentIds];
            }
        }
    };
   return createSelector(selectMultiUserAppointmentState, appointmentEntityByConferenceID);
}

export function selectByConferenceIdList(conferenceIds: number[]): MemoizedSelector<EntityStateMultiUserAppointment, MultiUserAppointment[]> {
    const appointmentEntityByConferenceIDList = (state: EntityStateMultiUserAppointment) => {
        const appointments = [];
        for (const appointmentIds in state.entities) {
            if (conferenceIds.includes(state.entities[appointmentIds].conferenceId)) {
                appointments.push(state.entities[appointmentIds]);
            }
        }
        return appointments;
    };
    return createSelector(selectMultiUserAppointmentState, appointmentEntityByConferenceIDList);
}

