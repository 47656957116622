<div class='create-tan-body content-wrapper card card-top'>
    <div class='appointment-success-container'>
        <div *ngIf="isEmailSent === 'false'">
            <div id='check'>
                <span class="fal fa-check fa-5x" aria-hidden="true"></span>
            </div>
            <div id='header'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.HEADER' | translate }}</div>
        </div>
        <div *ngIf="isEmailSent === 'true'">
            <div id='check'>
                <img
                    src="/assets/img/general/mail-check.svg"
                    class="email-sent"
                    alt="email-sent">
            </div>
            <div id='header'>{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.HEADER-WITH-EMAIL' | translate }}</div>
        </div>
        <div id='footer-text'>
            {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.FOOTER-TEXT' | translate }}
            <br/>
            {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.FOOTER-TEXT-NOTE' | translate }}
        </div>
        <div id='tan-container' *ngFor="let item of items">
            <div class="user-name weight-400">
                {{item.userFullName}}:
            </div>
            <div class="tan weight-500"
                 ngxClipboard
                 [cbContent]="copyLink(item.tan)"
                 [autoClose]="true"
                 placement="bottom"
                 triggers="manual"
                 #tooltipTan="ngbTooltip"
                 ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-LINK-COPIED' | translate }}"
                 (cbOnSuccess)="toggleTooltip(tooltipTan)">
                {{item.tan}}
            </div>
            <span id='copy-to-clipboard'
                  class='fal fa-copy fa-1x'
                  ngxClipboard
                  [cbContent]="item.tan"
                  ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-COPIED' | translate }}"
                  [autoClose]="true"
                  placement="bottom"
                  triggers="manual"
                  #tanTip="ngbTooltip"
                  (cbOnSuccess)="toggleTooltip(tanTip)"></span>
            <span id='copy-link'
                  class='fal fa-link fa-1x'
                  [autoClose]="true"
                  placement="bottom"
                  triggers="manual"
                  #tanLink="ngbTooltip"
                  ngxClipboard
                  ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.TAN-LINK-COPIED' | translate }}"
                  [cbContent]="copyLink(item.tan)"
                  (cbOnSuccess)="toggleTooltip(tanLink)"></span>
        </div>
        <a class="copy-all-link"
           [autoClose]="true"
           placement="bottom"
           triggers="manual"
           #copyAll="ngbTooltip"
           ngxClipboard
           ngbTooltip="{{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.COPY-ALL' | translate }}"
           [cbContent]="items"
           (cbOnSuccess)="toggleTooltip(copyAll)"
           (click)="copyAllNamesAndTans()">
            {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.COPY-ALL' | translate }}
            <span id="copy-all" class='fal fa-copy fa-1x'>
            </span>
        </a>
        <div id="form-footer" class="card-footer clearfix footer-space">
            <div class="footer-right">
                <button type="button"
                        id='gotoWaitingRoomButton'
                        (click)='goAppointmentUpcoming()'
                        class="btn btn-outline-primary btn-outlined">
                    {{'APPOINTMENT.TAN-CREATE-SUCCESSFUL.GO-TO-WAITINGROOM-BUTTON-TEXT' | translate }}
                </button>
                <button type="button"
                        id='createNewAppointmentButton'
                        (click)='goToCreateAppointment()'
                        class="btn btn-primary">
                    {{ 'APPOINTMENT.TAN-CREATE-SUCCESSFUL.GO-TO-TAN-CREATE-BUTTON-TEXT' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

