import { createReducer, on } from '@ngrx/store';
import { initialMultiUserAppointmentListState, multiUserAppointmentAdapter } from './multi-user-appointment.entity';
import { multiUserAppointmentsFetchSuccessAction, removeAllMultiUserAppointmentsAction } from './multi-user-appointment.action';


export const multiUserAppointmentReducer = createReducer(
    initialMultiUserAppointmentListState,
    on(multiUserAppointmentsFetchSuccessAction, (state, { items, pagination }) => {
        pagination = {
            ...pagination,
            pageNumber: pagination.pageNumber + 1
        }
        return {
            ...multiUserAppointmentAdapter.setAll(items, state),
            pagination,
            isInit: false,
            tanAppointmentId: null
        };
    }),
    on(removeAllMultiUserAppointmentsAction, () => {
        return initialMultiUserAppointmentListState;
    })
);

