import {createFeatureSelector} from '@ngrx/store';
import {State} from '../consultation-hour/store/consultation-hour.types';
import {ProductState} from '../product/store/product.types';

export interface AppointmentTypeState {
    appointmentProduct: ProductState;
    consultationHour: State;
}

export const selectAppointmentTypeState = createFeatureSelector<AppointmentTypeState>('appointment-type');
