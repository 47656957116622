import { createEntityAdapter, EntityState } from '@ngrx/entity';

export enum AppointmentTypes {
    'one_time_appointment' = 1,
    'bookable_appointment' = 2,
}

export interface Appointment {
    id: number;
    conferenceId: number;
    profileIds: number[];
    startDateTime: string;
    endDateTime: string;
    topic: string;
    tan?: string;
    period?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    insuranceNumber?: string | null;
    appointmentMessage?: string | null;
}

export const appointmentAdapter = createEntityAdapter<Appointment>();

export interface EntityStateAppointment extends EntityState<Appointment> {
    ids: string[];
    entities: { [id: string]: Appointment };
    isInit: boolean | null;
    tanAppointmentId: string | null;
}

const myDefaultAppointmentList: EntityStateAppointment = {
    isInit: true,
    tanAppointmentId: null,
    ids: [],
    entities: {}
};

export const initialAppointmentListState: EntityStateAppointment = myDefaultAppointmentList;

