import { Profile } from '../../profile/store/profile.entity';
import { Conference } from '../../conference/store/conference.entity';
import { Appointment } from '../store/one-time-appointment/appointment.entity';
import { AppointmentRequest } from '../../appointment-request/store/appointment-request.entity';

export interface CreateTanFormValues {
    user: {
        title: string;
        firstName: string;
        surname: string;
        gender: {
            id: string;
        };
        birthday?: string;
        insuranceNumber?: string;
    };
    tan: {
        validFrom: string;
        validTo: string;
        email?: string;
        emailMessage?: string;
    };
    term: {
        dataProtectionTerm?: number;
        dataProcessTerm?: number;
        termsAndConditionsPatientTerm?: number;
        cancellationTerm?: number;
    };
    isEbm: boolean;
    appointmentMessage?: string;
}

export interface CreateTanResponse {
    profile: Profile;
    tan: string;
}

export interface CreateTanConferenceRequest {
    profileIds: number[];
    description?: string;
}

export interface CreateTanAppointmentRequest {
    profileIds: number[];
    conferenceId: number;
    appointmentType: number;
    tan: string;
    topic?: string;
    startDateTime: string;
    endDateTime: string;
    isEbm: boolean;
    insuranceNumber: string;
    appointmentMessage: string;
}

export class CreateTanAppointmentDTO {
    public tan: string;
    public profile: Profile;
    public conference: Conference;
    public appointment: Appointment;
    public appointmentRequest?: AppointmentRequest;

    constructor(public formValues: CreateTanFormValues) {
    }

    getEmailMessage(): undefined | string {
        return this.formValues.tan.emailMessage;
    }

    getProfileId(): number {
        return this.profile.id;
    }

    getAppointmentStartDateTime(): string {
        return this.formValues.tan.validFrom;
    }

    getConferenceId(): number {
        return this.conference.id;
    }

    getAppointmentEndDateTime(): string {
        return this.formValues.tan.validTo;
    }

    isEbm(): boolean {
        return (this.formValues.isEbm === true) ? true : false;
    }

    getInsuranceNumber(): string {
        return this.formValues.user.insuranceNumber;
    }

    getAppointmentMessage(): string | null {
        return this.formValues.appointmentMessage || null;
    }
}
