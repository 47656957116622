<form #consultatonHourForm="ngForm" novalidate class="product-form">
    <div class="form-row">
        <div class="form-group col-md-9">
            <label for="title">
                <span class='required'>*</span>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TITLE-LABEL' | translate }}
                <span class='far fa-question-circle'
                      aria-hidden="true"
                      placement="bottom"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TITLE-HELP' | translate }}"></span>
            </label>
            <input type="text"
                   name="title"
                   #title="ngModel"
                   ngModel
                   required
                   maxlength="30"
                   appInvalidDirective
                   class="form-rounded form-control"
                   id="title"
                   placeholder="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TITLE-PLACEHOLDER' | translate }}"
                   ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TITLE-ERROR' | translate }}"
                   placement="bottom"
                   triggers="manual">
        </div>
        <div class="form-group col-md-3">
            <label for="duration">
                <span class='required'>*</span>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DURATION-LABEL' | translate }}
                <span class='far fa-question-circle'
                      aria-hidden="true"
                      placement="bottom"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DURATION-HELP' | translate }}"></span>
            </label>
            <select id="duration"
                    class="form-control input-select"
                    [class.placeholder]="isDefaultDurationSelected()"
                    name="duration"
                    #duration="ngModel"
                    [(ngModel)]="selectedDuration"
                    [compareWith]="compareByItem"
                    appInvalidDirective
                    required
                    ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DURATION-ERROR' | translate }}"
                    placement="bottom"
                    triggers="manual">
                <option disabled selected [ngValue]="{id: '0', name: ''}">
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DURATION-PLACEHOLDER' | translate }}
                </option>
                <option *ngFor="let duration of durations"
                        [ngValue]="duration">{{duration.name}}
                </option>
            </select>
        </div>
        <div class="form-group col-md-12">
            <label for="description">
                <span class='required'>*</span>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DESCRIPTION-LABEL' | translate }}
                <span class='far fa-question-circle'
                      aria-hidden="true"
                      placement="bottom"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DESCRIPTION-HELP' | translate }}"></span>
            </label>
            <input type="text"
                   name="description"
                   class="form-rounded form-control"
                   id="description"
                   #description="ngModel"
                   ngModel
                   required
                   maxlength="300"
                   appInvalidDirective
                   placeholder="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DESCRIPTION-PLACEHOLDER' | translate }}"
                   ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DESCRIPTION-ERROR' | translate }}"
                   placement="bottom"
                   triggers="manual">
        </div>
        <div class="form-group col-md-12">
            <label>
                <span class='required'>*</span>
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-LABEL' | translate }}
                <span class='far fa-question-circle'
                      aria-hidden="true"
                      placement="bottom"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-HELP' | translate }}"></span>
            </label><br>
            <input type="radio"
                   class="radio-option"
                   value="free"
                   id="ebm"
                   ngModel
                   required
                   #type="ngModel"
                   name="type"
                   checked>
            <label for="ebm">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-1-LABEL' | translate }}
            </label><br>
            <p *ngIf="consultatonHourForm.value.type === 'free'" class="notice">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-1-TEXT-1' | translate }}
            </p>
            <input type="radio"
                   class="radio-option"
                   value="not_free"
                   id="goa"
                   required
                   ngModel
                   appInvalidDirective
                   ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-ERROR' | translate }}"
                   placement="bottom"
                   triggers="manual"
                   name="type"> <label for="goa">
            {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TYPE-2-LABEL' | translate }}
        </label><br>
        </div>
    </div>
    <div class="product-price-item-space" *ngIf="consultatonHourForm.value.type === 'not_free'">
        <div class="form-row">
            <div class="col-md-10 form-row">
                <div class="form-group col-md-4">
                    <label>
                        <span class='required'>*</span>{{
                        'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-ZIFFER-LABEL' | translate }}
                        <span class='far fa-question-circle'
                              aria-hidden="true"
                              placement="bottom"
                              ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-ZIFFER-HELP' | translate }}"></span>
                    </label><br>
                </div>
                <div class="form-group col-md-4">
                    <label>
                        <span class='required'>*</span>{{
                        'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-VALUE-LABEL' | translate }}
                        <span class='far fa-question-circle'
                              aria-hidden="true"
                              placement="bottom"
                              ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-VALUE-HELP' | translate }}"></span>
                    </label><br>
                </div>
                <div class="form-group col-md-4">
                    <label>
                        <span class='required'>*</span>
                        {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.STEIGERUNGSATZ-LABEL' | translate }}
                        <span class='far fa-question-circle'
                              aria-hidden="true"
                              placement="bottom"
                              ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.STEIGERUNGSATZ-HELP' | translate }}"></span>
                    </label><br>
                </div>
            </div>
            <div class=" form-group col-md-2 align-center">
                <label>
                    {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.SUM' | translate }}
                </label><br>
            </div>
        </div>
        <div ngModelGroup="productPriceItems">
            <div class="form-row" *ngFor="let priceItemId of productPriceItemIds; let i=index;">
                <div class="col-md-10 form-row">
                    <div class="form-group col-md-4">
                        <input type="number"
                               name="billingCode[{{priceItemId}}]"
                               #billingCode="ngModel"
                               ngModel
                               required
                               class="form-rounded form-control"
                               id="billingCode"
                               min="0"
                               step="1"
                               pattern="\d*"
                               placeholder="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-ZIFFER-PLACEHOLDER' | translate }}"
                               ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-ZIFFER-ERROR' | translate }}"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective>
                        <i class="fal fa-angle-right"></i>
                    </div>
                    <div class="form-group col-md-4">
                        <input type="number"
                               name="paymentValue[{{priceItemId}}]"
                               #paymentValue="ngModel"
                               ngModel
                               required
                               min="0"
                               pattern="^[0-9]{1,2}([,.][0-9]{1,2})?$"
                               class="form-rounded form-control"
                               id="paymentValue"
                               placeholder="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-VALUE-PLACEHOLDER' | translate }}"
                               ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.GOA-VALUE-ERROR' | translate }}"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective
                               step="1">
                        <i class="fal fa-angle-right"></i>
                    </div>
                    <div class="form-group col-md-4">
                        <input type="number"
                               name="multiplicator[{{priceItemId}}]"
                               #multiplicator="ngModel"
                               ngModel
                               required
                               min="0"
                               pattern="^[0-9]{1,2}([,.][0-9]{1,2})?$"
                               class="form-rounded form-control"
                               id="multiplicator"
                               placeholder="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.STEIGERUNGSATZ-PLACEHOLDER' | translate }}"
                               ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.STEIGERUNGSATZ-ERROR' | translate }}"
                               placement="bottom"
                               triggers="manual"
                               appInvalidDirective
                               step="1">
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <i class="fal fa-angle-double-right"></i>
                    <span class="price-and-button-wrapper">
                        {{ getLineItemSum(priceItemId) | number:'1.2-2' | noComma | commaSeperated }} €
                        <button class="btn btn-round btn-primary" (click)="removeProductPriceItem(i)"><i
                                class="fa fa-minus"></i></button>
                        </span>
                </div>
            </div>
            <div class="form-row flex-end">
                <div class="form-group">
                    <div class="add-button-and-sum-wrapper add-product-item-button"
                         [class.inactive]="!areProductPriceItemsValid()"
                         (click)="addProductPriceItem()">
                        <span>{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.ADD-LINE-ITEM' | translate }}</span>
                        <button class="btn btn-round btn-primary"><i
                                class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="sum-text-wrapper-container">
                    <div class="sum-text-wrapper">
                        <span class="sum-text roboto"> {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.TOTAL-COST' | translate }}</span>
                        <span class="sum roboto"> {{ getTotalSum() | number:'1.2-2' | noComma | commaSeperated }} €</span>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <p class="notice">{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.NOTICE' | translate }}</p>
            </div>
        </div>
    </div>
    <div class="more-option">
        <div class="expand-link-wrapper">
            <span (click)="expanded = !expanded" class="">{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.TOGGLE-LINK' | translate }}
                <i class="fas fa-caret-down" *ngIf="!expanded"></i>
                <i class="fas fa-caret-up" *ngIf="expanded"></i>
            </span>
        </div>
        <div class="more-option-expandable-space form-group" *ngIf="expanded">
            <label for="hours">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.LABEL' | translate }}<span
                    class="font-weight-light">{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.TIME-SPECIFICATION' | translate }}</span>
                <span class='far fa-question-circle'
                      aria-hidden="true"
                      placement="bottom"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.TOOLTIP' | translate }}"></span>
            </label>
            <div class="hour-minute-input-wrapper">
                <input type="number"
                       name="hours"
                       min="0"
                       step="1"
                       pattern="\d*"
                       [(ngModel)]="vanishTime.hours"
                       class="form-rounded form-control"
                       id="hours"
                       ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.HOUR-ERROR' | translate }}"
                       placement="bottom"
                       triggers="manual"
                       appInvalidDirective>
                :
                <input type="number"
                       name="minutes"
                       min="0"
                       step="1"
                       pattern="\d*"
                       (blur)="updateVanishTimeMinutes($event.target.value)"
                       [(ngModel)]="vanishTime.minutes"
                       class="form-rounded form-control"
                       id="minutes"
                       ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.MORE-OPTIONS.UNBOOKABLE-TIME.MINUTES-ERROR' | translate }}"
                       placement="bottom"
                       triggers="manual"
                       appInvalidDirective>
            </div>
        </div>
    </div>
    <div class="card-footer clearfix">
        <div class="footer-left">
            <span class='required'>*</span>{{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.REQUIRED' | translate }}
        </div>
        <div class="footer-right">
            <button *ngIf="showCancel"
                    id="cancelButton"
                    class="btn btn-link color-white"
                    (click)="onCancel.emit()">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.CANCEL' | translate }}
            </button>
            <button *ngIf="showDelete"
                    id="deleteButton"
                    class="btn btn-danger"
                    (click)="onDelete.emit()">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.DELETE' | translate }}
                <i class="fas fa-trash"></i>
            </button>
            <button *ngIf="showCreate"
                    type="submit"
                    id='submitCreateButton'
                    class="btn btn-success"
                    (click)="onFormSubmit()"
                    [ngClass]="{'invalid': consultatonHourForm.invalid}">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.CREATE' | translate }}
                <span class='fal fa-check-circle' aria-hidden="true"></span>
            </button>
            <button *ngIf="showSave"
                    type="submit"
                    id='submitSaveButton'
                    class="btn btn-success"
                    (click)="onFormSubmit()"
                    [ngClass]="{'invalid': consultatonHourForm.invalid}">
                {{ 'APPOINTMENT.CREATE-CONSULTATION.PRODUCT-FORM.SAVE' | translate }}
                <span class='fal fa-check-circle' aria-hidden="true"></span>
            </button>
        </div>
    </div>
</form>
