import { Action } from '@ngrx/store';
import { Conference } from './conference.entity';

export const CONFERENCE_ACTIVATE = 'CONFERENCE_ACTIVATE';
export const CONFERENCE_LIST_UPDATE_SUCCESS = 'CONFERENCE_LIST_UPDATE_SUCCESS';
export const CONFERENCE_LIST_ADD_SUCCESS = 'CONFERENCE_LIST_ADD_SUCCESS';
export const CONFERENCE_FAIL_ACTION = 'CONFERENCE_FAIL_ACTION';

export const CONFERENCE_ADD = 'CONFERENCE_ADD';
export const CONFERENCE_REMOVE_ALL = 'CONFERENCE_REMOVE_ALL';

export interface ConferenceAction extends Action {
    readonly type: string;
    payload: Conference | Conference[] | number | null;
    errors: string[] | null;
}

export class ConferenceActivateAction implements ConferenceAction {
    readonly type = CONFERENCE_ACTIVATE;
    errors = null;

    constructor(public payload: Conference) {
    }
}

export class ConferenceListAddSuccessAction implements ConferenceAction {
    readonly type = CONFERENCE_LIST_ADD_SUCCESS;
    errors = null;
    constructor(public payload: Conference[]) {
    }
}

export class ConferenceListUpdateSuccessAction implements ConferenceAction {
    readonly type = CONFERENCE_LIST_UPDATE_SUCCESS;
    errors = null;
    constructor(public payload: Conference[]) {
    }
}

export class ConferenceFailAction implements ConferenceAction {
    readonly type = CONFERENCE_FAIL_ACTION;
    readonly payload:  null;
    constructor(readonly errors: string[]) {
    }
}

export class ConferenceAddAction implements ConferenceAction {
    readonly type = CONFERENCE_ADD;
    errors = null;

    constructor(public payload: Conference) { }
}


export class ConferenceRemoveAllAction implements ConferenceAction {
    readonly type = CONFERENCE_REMOVE_ALL;
    errors = null;
    payload = null;
}
