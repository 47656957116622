import { ConsultationHour } from '../../consultation-hour.model';
import { ConsultationHourDto } from './consultation-hour-dto';
import moment from 'moment';
import {
    DATE_TIME_FORMAT_EXPORT,
    DATE_TIME_FORMAT_FRONTEND,
    TIME_FORMAT_EXPORT,
    TimeHelperService
} from '../../../../../utils/time-helpers/time-helper.service';

export class ConsultationHourMapper {

    public static mapDtoToEntity(dto: ConsultationHourDto): ConsultationHour {
        const consultationHour = {
            ...dto,
            id: dto.consultationHourId,
            isActive: (dto.isActive === 1),
            startTime: moment(moment.utc(dto.startTime, 'YYYY-MM-DD\THH:mm:ss').local().format('HH:mm:ss'), 'HH:mm:ss'),
            endTime: moment(moment.utc(dto.endTime, 'YYYY-MM-DD\THH:mm:ss').local().format('HH:mm:ss'), 'HH:mm:ss'),
            day: Number(dto.day)
        };
        delete consultationHour.consultationHourId;
        return consultationHour;
    }

    public static mapEntityToDto(entity: ConsultationHour): ConsultationHourDto {
        const startTime = ConsultationHourMapper.convertDate(entity.startTime);
        const endTime = ConsultationHourMapper.convertDate(entity.endTime);

        const dto = {
            ...entity,
            consultationHourId: entity.id,
            isActive: (entity.isActive) ? 1 : 0,
            startTime,
            endTime,
            day: String(entity.day)
        };
        delete dto.id;
        return dto;
    }


    private static convertDate(time: moment.Moment): string {
        const timeString = time.format(TIME_FORMAT_EXPORT);
        const day = TimeHelperService.getMomentLocalNow().format('YYYY-MM-DD');
        return moment(day + ' ' + timeString).utc().format(DATE_TIME_FORMAT_EXPORT);
    }
}

