import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

export interface TanItem {
    tan: string;
    userFullName: string;
}

@Injectable({
    providedIn: 'root'
})
export class CreateMultiUserSuccessHandlerService {

    public items: Set<TanItem> = new Set<TanItem>();
    public error: HttpErrorResponse = null;

    constructor() {
    }
}
