export enum AppointmentStatus {
    UNCONFIRMED = 'unconfirmed',
    PENDING = 'pending',
    REJECTED = 'rejected',
    RUNNING = 'running',
    SUCCESS = 'success',
    CANCELED = 'canceled'
}

export const appointmentStatus = {
    [AppointmentStatus.UNCONFIRMED]: 1,
    [AppointmentStatus.PENDING]: 2,
    [AppointmentStatus.REJECTED]: 3,
    [AppointmentStatus.RUNNING]: 4,
    [AppointmentStatus.SUCCESS]: 5,
    [AppointmentStatus.CANCELED]: 6
};
