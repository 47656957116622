import { map, switchMap } from 'rxjs/operators';
import { Appointment, EntityStateAppointment } from '../store/one-time-appointment/appointment.entity';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
    getIsInitSelector,
    selectAll,
    selectByConferenceId,
    selectByConferenceIdList,
    totalAppointments
} from '../store/one-time-appointment/appointment.selector';
import { DATE_TIME_FORMAT_FRONTEND, TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { Observable, of } from 'rxjs';
import { Conference } from '../../conference/store/conference.entity';

interface RawAppointmentsResponse {
    profileId: string;
    items: Appointment[];
}

@Injectable()
export class AppointmentService {
    constructor(
        private http: HttpClient,
        private store: Store<EntityStateAppointment>
    ) {
    }

    public getAppointments(): Observable<Appointment[]> {
        return this.http.get(environment.appointmentsEndpoint, {}).pipe(
            switchMap(rawAppointments => {
                if (!rawAppointments || rawAppointments === 'null') {
                    return of([]);
                }
                const appointments = (<RawAppointmentsResponse>rawAppointments).items;

                return of(appointments);
            }));
    }

    public populateTimeData(data: Appointment[] | Appointment): Observable<Appointment[] | Appointment> {
        if (Array.isArray(data)) {
            // TODO: check if map return unmutalbe array or changes base array
            data.map((appointment) => {
                appointment = this.addTimeData(appointment);
            });

            return of(data);
        }

        return of(this.addTimeData(data));
    }

    public addTimeData(appointment: Appointment): Appointment {
        const startDateTime = TimeHelperService.utcToLocalMomentDateTime(appointment.startDateTime);
        const endDateTime = TimeHelperService.utcToLocalMomentDateTime(appointment.endDateTime);

        appointment.startDateTime = startDateTime.format(DATE_TIME_FORMAT_FRONTEND);
        appointment.endDateTime = endDateTime.format(DATE_TIME_FORMAT_FRONTEND);

        appointment.period = endDateTime.diff(startDateTime, 'minutes') + ' min';
        appointment.startTime = startDateTime.format('HH:mm a');
        appointment.endTime = endDateTime.format('HH:mm a');

        return appointment;
    }

    public getAllAppointmentsObserver(): Observable<Appointment[]> {
        return this.store.select(selectAll);
    }

    public getAppointmentsCountObserver(): Observable<number> {
        return this.store.select(totalAppointments);
    }

    public isInit(): Observable<boolean> {
        return this.store.select(getIsInitSelector);
    }

    public compareByAppointmentDate(a: Appointment, b: Appointment): number {
        if (new Date(a.startDateTime) < new Date(b.startDateTime)) {
            return -1;
        } else if (new Date(a.startDateTime) > new Date(b.startDateTime)) {
            return 1;
        } else {
            return 0;
        }
    }

    public isUpcomingAppointment(appointment: Appointment): boolean {
        return (new Date(appointment.startDateTime) > new Date()) || TimeHelperService.isToday(appointment.startDateTime);
    }

    public getAppointmentByConferenceId(conferenceId: number): Observable<Appointment> {
        return this.store.select(selectByConferenceId(conferenceId));
    }

    public getAppointmentByConferenceIdList(conferenceIdList: number[]): Observable<Appointment[]> {
        return this.store.select(selectByConferenceIdList(conferenceIdList));
    }

    public getOnlyUpcomingAppointmentConferences(confernces: Conference[]): Observable<Conference[]> {
        if (confernces === null) {
            return of([]);
        }
        const ids = [];
        const conferenceMap = new Map();
        for (const confernce of confernces) {
            ids.push(confernce.id);
            conferenceMap.set(confernce.id, confernce);
        }
        return this.getAppointmentByConferenceIdList(ids).pipe(
            map((appointmentList: Appointment[]) => {
                const filteredConferences = [];
                for (const appointment of appointmentList) {
                    if (this.isUpcomingAppointment(appointment)) {
                        filteredConferences.push(conferenceMap.get(appointment.conferenceId));
                    }
                }
                return filteredConferences;
            }),
        );
    }
}
