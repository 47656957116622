import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {ConsultationHour} from '../consultation-hour.model';
import {ConsultationHourActions, ConsultationHourActionTypes} from './consultation-hour.actions';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppointmentTypeState, selectAppointmentTypeState} from '../../store/appointment-type.state';

import {State} from './consultation-hour.types';


export const adapter: EntityAdapter<ConsultationHour> = createEntityAdapter<ConsultationHour>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

export function reducer(state = initialState,
                        action: ConsultationHourActions): State {
    switch (action.type) {
        case ConsultationHourActionTypes.AddConsultationHour: {
            return adapter.addOne(action.payload.consultationHour, state);
        }

        case ConsultationHourActionTypes.AddConsultationHours: {
            return adapter.addMany(action.payload.consultationHours, state);
        }

        case ConsultationHourActionTypes.UpdateConsultationHour: {
            return adapter.updateOne(action.payload.consultationHour, state);
        }

        case ConsultationHourActionTypes.UpdateConsultationHours: {
            return adapter.updateMany(action.payload.consultationHours, state);
        }

        case ConsultationHourActionTypes.DeleteConsultationHour: {
            return adapter.removeOne(action.payload.id, state);
        }

        case ConsultationHourActionTypes.DeleteConsultationHours: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case ConsultationHourActionTypes.LoadConsultationHours: {
            return adapter.setAll(action.payload.consultationHours, state);
        }

        case ConsultationHourActionTypes.ClearConsultationHours: {
            return adapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const selectConsultationHourState = createSelector(
    selectAppointmentTypeState,
    (state: AppointmentTypeState) => state.consultationHour
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors(selectConsultationHourState);

export function getConsultationHourByIdSelector(id: number): MemoizedSelector<State, ConsultationHour> {
    const consultationHourIdState = (state: State) => state.entities[id];

    const consultationHourIdSelector = createSelector(selectConsultationHourState, consultationHourIdState);
    return consultationHourIdSelector;
}
