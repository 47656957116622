import { Appointment, appointmentAdapter, EntityStateAppointment } from './appointment.entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';


export const selectAppointmentState = createFeatureSelector<EntityStateAppointment>('appointment');

export const {
    selectIds: idList,
    selectEntities: allAppointments,
    selectAll: selectAll,
    selectTotal: totalAppointments
} = appointmentAdapter.getSelectors(selectAppointmentState);

export const isInitSelector = (state: EntityStateAppointment) => state.isInit;
export const tanAppointmentIdSelector = (state: EntityStateAppointment) => state.tanAppointmentId;

export const getIsInitSelector = createSelector(selectAppointmentState, isInitSelector);
export const getTanAppointmentIdSelectorSelector = createSelector(selectAppointmentState, tanAppointmentIdSelector);

export const selectCreatedAppointment = createSelector(
    allAppointments,
    getTanAppointmentIdSelectorSelector,
    (allEntities, selected) => allEntities[selected]
);

export function selectByConferenceId(conferenceId: number): MemoizedSelector<EntityStateAppointment, Appointment> {
    const appointmentEntityByConferenceID = (state: EntityStateAppointment) => {
        for (const appointmentIds in state.entities) {
            if (state.entities[appointmentIds].conferenceId === conferenceId) {
                return state.entities[appointmentIds];
            }
        }
    };
    const appointmentByConferenceIdSelector = createSelector(selectAppointmentState, appointmentEntityByConferenceID);
    return appointmentByConferenceIdSelector;
}

export function selectByConferenceIdList(conferenceIds: number[]): MemoizedSelector<EntityStateAppointment, Appointment[]> {
    const appointmentEntityByConferenceIDList = (state: EntityStateAppointment) => {
        const appointments = [];
        for (const appointmentIds in state.entities) {
            if (conferenceIds.includes(state.entities[appointmentIds].conferenceId)) {
                appointments.push(state.entities[appointmentIds]);
            }
        }
        return appointments;
    };
    const appointmentByConferenceIdSelector = createSelector(selectAppointmentState, appointmentEntityByConferenceIDList);
    return appointmentByConferenceIdSelector;
}

