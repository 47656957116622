import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Appointment, EntityStateAppointment } from '../../../store/one-time-appointment/appointment.entity';
import { selectCreatedAppointment } from '../../../store/one-time-appointment/appointment.selector';
import { APPOINTMENT_OVERVIEW_UPCOMING, TAN_APPOINTMENT_CREATE_ROUTE } from '../../../../../routes';
import { RouterHelperService } from '../../../../utils/router-helper/router-helper.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-create-tan-successful',
    templateUrl: './create-tan-successful.component.html',
    styleUrls: ['./create-tan-successful.component.scss']
})
export class CreateTanSuccessfulComponent {
    public tan$: Observable<string>;
    public tanLink$: Observable<string>;
    public hideCopiedSuccessful = true;
    public isEmailSent: string;

    constructor(
        private translate: TranslateService,
        private store: Store<EntityStateAppointment>,
        private router: RouterHelperService,
        private route: ActivatedRoute) {
        this.tan$ = this.store.select(selectCreatedAppointment).pipe(map((appointment: Appointment) => appointment ? appointment.tan : ''));
        this.tanLink$ = this.tan$.pipe(map((tan: string) => `${window.location.origin}/tan-login?tan=${tan}`));
        this.route.queryParams.subscribe(params => this.isEmailSent = params['emailsent']);
    }

    public goToCreateAppointment(): void {
        this.router.navigate([TAN_APPOINTMENT_CREATE_ROUTE]);
    }

    public goAppointmentUpcoming(): void {
        this.router.navigate([APPOINTMENT_OVERVIEW_UPCOMING]);
    }

    public copiedSuccessful(): void {
        this.hideCopiedSuccessful = false;
        setTimeout(() => this.hideCopiedSuccessful = true, 2000);
    }
}
