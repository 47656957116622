import { Injectable } from '@angular/core';
import { AppConfigService} from '../../../providers/app-config/app-config.service';
import { FeatureFlag, FeatureFlagConfig } from '../feature-flag.types';
import { AppConfigKey } from '../../../providers/app-config/app-config.types';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
    private readonly featureFlagConfig: FeatureFlagConfig;

    constructor(private appConfigService: AppConfigService) {
        this.featureFlagConfig = this.appConfigService.getConfig(AppConfigKey.FEATURE_FLAG);
    }

    public isFeatureEnabled(featureFlag: FeatureFlag): boolean {
        return this.featureFlagConfig[featureFlag];
    }
}
