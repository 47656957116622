<div class="day-wrapper">
    <h3 class="day-title">{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.WEEK-DAY.' + dayOfWeek | translate }}</h3>
    <div class="card blue-card no-shadow" *ngIf="(consultationHourList$ | async).length <= 0 && createNewConsultationHour">
        <app-consultation-hour-form
                [prefillConsultationHours]="consultationHourList$"
                (onCancel)="createNewConsultationHour = false;"
                (onSave)="createNewConsultationHour = false;"
                [weekDay]="dayOfWeek">
        </app-consultation-hour-form>
    </div>
    <div class="card blue-card no-shadow" *ngIf="(consultationHourList$ | async).length > 0 && modifyConsultationHour">
        <app-consultation-hour-form
                [prefillConsultationHours]="consultationHourList$"
                (onCancel)="modifyConsultationHour = false;"
                (onSave)="modifyConsultationHour = false;"
                [weekDay]="dayOfWeek">
        </app-consultation-hour-form>
    </div>
    <div class="card blue-card ch-summary no-shadow" *ngIf="(consultationHourList$ | async).length > 0 && !modifyConsultationHour">
        <div class="no-modify-view" (click)="modifyConsultationHour = true;">
            <div class="modify-icon">
                <i class="fas fa-pen-alt"></i><i class="fal fa-ellipsis-h pull-down"></i>
            </div>
        </div>
        <div class="consultation-hour-preview-line" *ngFor="let consultationHour of (consultationHourList$ | async)">
            <span class="time">
                {{ mapMomentToTimeString(consultationHour.startTime) }} - {{ mapMomentToTimeString(consultationHour.endTime) }}:
            </span>
            <ng-container *ngFor="let chItem of consultationHour.consultationHourItems">
                <span class="badge"
                      *ngIf="findProductById(chItem.productId) as product"
                      ngbTooltip="{{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.TIMESPAN-TOO-SHORT-TOOLTIP' | translate }}"
                      placement="bottom"
                      [class.too-long-for-timespan]="isProductTooLongForTimespan(product, consultationHour.startTime, consultationHour.endTime)">{{ product.title }}</span>
            </ng-container>
        </div>
    </div>
    <button class="btn btn-primary"
            *ngIf="!createNewConsultationHour && (consultationHourList$ | async).length <= 0"
            (click)="createNewConsultationHour = true;">
        <i class="fal fa-plus"></i>
        {{ 'APPOINTMENT.CREATE-CONSULTATION.TIMEFRAME-FORM.ADD-TIMEFRAME' | translate }}
    </button>
</div>
