import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface Conference {
    id: number;
    myToken:  ConferenceToken;
    userTokens: { [id: number]: ConferenceToken };
}

export interface ConferenceToken {
    id: number;
    token: string;
    profileId: number;
}

export const conferenceAdapter = createEntityAdapter<Conference>();

export interface EntityStateConference extends EntityState<Conference> {
    ids: number[];
    entities: { [id: number]: Conference };
    activeId: number | null;
}

const myDefaultConferenceList: EntityStateConference = {
    activeId: null,
    ids: [],
    entities: {}
};

export const initialConfenceListState: EntityStateConference = myDefaultConferenceList;
