export interface GenderType {
    id: string;
    name: string;
}

export const GendersGerman = [
    { id: 'm', name: 'Herr' },
    { id: 'f', name: 'Frau' }
];

export const GendersEnglish = [
    { id: 'm', name: 'Mr' },
    { id: 'f', name: 'Mrs' }
];
