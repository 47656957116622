
import {mapTo} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../../../providers/store/app.action';
import { ClearProducts } from './product.actions';


@Injectable()
export class ProductEffects {
    constructor(private actions$: Actions) {
    }

     doRemoveAllProducts$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new ClearProducts())));
}
