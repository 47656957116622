import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, Subscription, throwError } from 'rxjs';
import { LoggingAppointment } from '../logging.model';
import { TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { ConferenceService } from '../../conference-v2/providers/conference.service';
import { AppointmentService } from '../../appointment/providers/appointment.service';
import { FeatureFlagService } from '../../feature-flag/providers/feature-flag.service';
import { MultiUserAppointmentService } from '../../appointment/providers/multi-user-appointment.service';
import { FeatureFlag } from '../../feature-flag/feature-flag.types';

@Injectable()
export class LogAppointmentService {

    constructor(private http: HttpClient,
                private conferenceService: ConferenceService,
                private appointmentService: AppointmentService,
                private multiUserAppointmentService: MultiUserAppointmentService,
                private featureFlagService: FeatureFlagService) {
    }

    public logAppointmentCreated(appointmentId: number): void {
        this.http
            .post(environment.logAppointmentCreated, {appointmentId, actionDateTime: this.setCurrentUtcTime()})
            .pipe(
                take(1),
                catchError(this.handleError)).subscribe();
    }

    public logAppointmentStarted(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentStarted, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentCountdownStarted(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentCountdownStarted, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentCountdownFinished(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentCountdownFinished, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentVideoConnectionEstablished(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentVideoConnectionEstablished, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentHangup(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentHangup, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentQuit(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentQuit, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentVideoConnectionNotEstablished(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentVideoConnectionNotEstablished, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentCanceled(): Subscription {
        return this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentCanceled, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentNoShow(): void {
        this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentNoShow, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    public logAppointmentVideoConnectionTimedOut(): void {
         this.getLoggingData().pipe(
            switchMap((data: LoggingAppointment) => this.http
                    .post(environment.logAppointmentVideoConnectionTimedOut, data)
                    .pipe(catchError(this.handleError))),
            take(1)).subscribe();
    }

    private setCurrentUtcTime(): string {
        return TimeHelperService.getCurrentUtcDateTime();
    }


    private getLoggingData(): Observable<LoggingAppointment> {
        const isMUCEnabled = this.featureFlagService.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL)
        return this.conferenceService
            .getActiveConferenceId().pipe(
                filter(c => c !== null),
                take(1),
                switchMap(conferenceId => {
                    return isMUCEnabled
                        ? this.multiUserAppointmentService.getMultiUserAppointmentByConferenceId(conferenceId)
                        : this.appointmentService.getAppointmentByConferenceId(conferenceId)
                }),
                filter(c => c !== null),
                take(1),
                map(appointment => appointment.id),
                map(id => ({appointmentId: id, actionDateTime: this.setCurrentUtcTime()}))
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in LogAppointmentService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
