export interface DurationType {
    id: string;
    name: string;
}

export const Durations = [
    {id: '05', name: '05 min'},
    {id: '10', name: '10 min'},
    {id: '15', name: '15 min'},
    {id: '20', name: '20 min'},
    {id: '30', name: '30 min'},
    {id: '40', name: '40 min'},
    {id: '45', name: '45 min'},
    {id: '50', name: '50 min'},
    {id: '60', name: '1 h'},
    {id: '70', name: '1 h 10 min'},
    {id: '80', name: '1 h 20 min'},
    {id: '90', name: '1 h 30 min'},
    {id: '100', name: '1 h 40 min'},
    {id: '110', name: '1 h 50 min'},
    {id: '120', name: '2 h'}
];
