import {catchError, map, mapTo, mergeMap, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AppointmentRequestService} from '../providers/appointment-request.service';
import {Action} from '@ngrx/store';
import {APP_CHANGE_TO_AUTHENTICATED_STATE} from '../../../providers/store/app.action';
import {AppointmentRequest} from './appointment-request.entity';
import {
    APPOINTMENT_REQUEST_ACCEPT,
    APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL,
    APPOINTMENT_REQUEST_REJECT,
    APPOINTMENT_REQUEST_REJECT_SUCCESSFUL,
    AppointmentRequestAcceptAction,
    AppointmentRequestAcceptFailedAction,
    AppointmentRequestAcceptSuccessfulAction,
    AppointmentRequestActionType,
    AppointmentRequestDeleteOne,
    AppointmentRequestRejectAction,
    AppointmentRequestRejectFailedAction,
    AppointmentRequestRejectSuccessfulAction,
    AppointmentsRequestAddAction,
    AppointmentsRequestReceivedSuccessfulAction
} from './appointment-request.action';
import {Observable, of} from 'rxjs';

@Injectable()
export class AppointmentRequestEffects {
    constructor(private actions$: Actions,
                private appointmentRequestsService: AppointmentRequestService) {
    }

     doRequestAppointmentRequests$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_AUTHENTICATED_STATE),
        switchMap(() => this.appointmentRequestsService.getAppointmentRequests().pipe(
                map((requests: AppointmentRequest[]) => new AppointmentsRequestReceivedSuccessfulAction(requests))))));

     doAcceptAppointmentrequest$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_REQUEST_ACCEPT),
        map((action: AppointmentRequestAcceptAction) => action.payload),
        mergeMap((appointmentRequest: AppointmentRequest) => this.appointmentRequestsService
                .acceptAppointmentRequest(appointmentRequest).pipe(
                    mapTo(new AppointmentRequestAcceptSuccessfulAction(appointmentRequest)),
                    catchError(err => of(new AppointmentRequestAcceptFailedAction(err))),))));

     doRejectAppointmentrequest$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_REQUEST_REJECT),
        map((action: AppointmentRequestRejectAction) => action.payload),
        mergeMap((appointmentRequest: AppointmentRequest) => this.appointmentRequestsService
                .rejectAppointmentRequest(appointmentRequest).pipe(
                    mapTo(new AppointmentRequestRejectSuccessfulAction(appointmentRequest)),
                    catchError(err => of(new AppointmentRequestRejectFailedAction(err))),))));

     doSaveAppointmentRequests$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppointmentRequestActionType.RECEIVED_SUCCESSFULL),
        map((action: AppointmentsRequestReceivedSuccessfulAction) => new AppointmentsRequestAddAction(action.payload))));

     doRemoveAppointemntRequestOnAccept$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL),
        map((action: AppointmentRequestAcceptSuccessfulAction) => new AppointmentRequestDeleteOne(
            (<AppointmentRequest>action.payload).id
        ))));

     doRemoveAppointemntRequestOnReject$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENT_REQUEST_REJECT_SUCCESSFUL),
        map((action: AppointmentRequestRejectSuccessfulAction) => new AppointmentRequestDeleteOne(
            (<AppointmentRequest>action.payload).id
        ))));

}

