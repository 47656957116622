import { Action } from '@ngrx/store';
import { AppointmentRequest } from './appointment-request.entity';

export interface AppointmentRequstAction extends Action {
    readonly type: string;
    payload: AppointmentRequest | AppointmentRequest[] | number | null;
    errors: string[] | null;
}


export enum AppointmentRequestActionType {
    RECEIVED_SUCCESSFULL = 'Received Successfull',
    ADD_APPOINTMENT_REQUESTS = 'Add Appointment Reqests',
    DELETE_ONE = 'Delete One Appointment Request'
}

export const APPOINTMENTS_REQUEST_RECEIVED_SUCCESSFUL = 'APPOINTMENTS_REQUEST_RECEIVED_SUCCESSFUL';

export class AppointmentsRequestReceivedSuccessfulAction implements AppointmentRequstAction {
    readonly type = AppointmentRequestActionType.RECEIVED_SUCCESSFULL;
    errors = null;

    constructor(public payload: AppointmentRequest[]) {
    }
}

export class AppointmentsRequestAddAction implements AppointmentRequstAction {
    readonly type = AppointmentRequestActionType.ADD_APPOINTMENT_REQUESTS;
    errors = null;

    constructor(public payload: AppointmentRequest[]) {
    }
}

export const APPOINTMENT_REQUEST_ACCEPT = 'APPOINTMENTS_REQUEST_ACCEPT';

export class AppointmentRequestAcceptAction implements AppointmentRequstAction {
    readonly type = APPOINTMENT_REQUEST_ACCEPT;
    errors = null;

    constructor(public payload: AppointmentRequest) {
    }
}

export const APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL = 'APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL';

export class AppointmentRequestAcceptSuccessfulAction implements AppointmentRequstAction {
    readonly type = APPOINTMENT_REQUEST_ACCEPT_SUCCESSFUL;
    errors = null;

    constructor(public payload: AppointmentRequest) {
    }
}

export const APPOINTMENT_REQUEST_ACCEPT_FAILED = 'APPOINTMENT_REQUEST_ACCEPT_FAILED';

export class AppointmentRequestAcceptFailedAction implements AppointmentRequstAction  {
    payload: AppointmentRequest | AppointmentRequest[] | number | any;
    type = APPOINTMENT_REQUEST_ACCEPT_FAILED;
    constructor(readonly errors: string[]) {

    }
}

export const APPOINTMENT_REQUEST_REJECT = 'APPOINTMENT_REQUEST_REJECT';

export class AppointmentRequestRejectAction implements AppointmentRequstAction {
    readonly type = APPOINTMENT_REQUEST_REJECT;
    errors = null;

    constructor(public payload: AppointmentRequest) {
    }
}

export const APPOINTMENT_REQUEST_REJECT_SUCCESSFUL = 'APPOINTMENT_REQUEST_REJECT_SUCCESSFUL';

export class AppointmentRequestRejectSuccessfulAction implements AppointmentRequstAction {
    readonly type = APPOINTMENT_REQUEST_REJECT_SUCCESSFUL;
    errors = null;

    constructor(public payload: AppointmentRequest) {
    }
}

export const APPOINTMENT_REQUEST_REJECT_FAILED = 'APPOINTMENT_REQUEST_REJECT_FAILED';

export class AppointmentRequestRejectFailedAction  {
    readonly type = APPOINTMENT_REQUEST_REJECT_FAILED;
    constructor(readonly errors: string[]) {
    }
}

export class AppointmentRequestDeleteOne implements Action {
    readonly type = AppointmentRequestActionType.DELETE_ONE;

    constructor(public payload: number) {
    }
}
