import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CreateMultiUserTanService } from '../../providers/create-multi-user-tan.service';
import {
    CreateMultiUserTanAppointmentResponse,
    MultiUserTanItemResponse
} from '../../providers/create-multi-user-tan.types';
import { RouterHelperService } from '../../../utils/router-helper/router-helper.service';
import { CreateMultiUserSuccessHandlerService, TanItem } from '../../providers/create-multi-user-success-handler.service';
import { CREATE_APPOINTMENT_FAILURE, CREATE_APPOINTMENT_SUCCESS } from '../../../../routes';
import {
    cancelMultiUserAppointmentsRequest,
    createMultiUserAppointmentAction,
    createMultiUserAppointmentErrorAction,
    createMultiUserAppointmentSuccessAction,
    fetchMultiUserAppointmentsAction,
    multiUserAppointmentsFetchErrorAction,
    multiUserAppointmentsFetchSuccessAction,
    removeAllMultiUserAppointmentsAction,
} from './multi-user-appointment.action';
import { MultiUserAppointmentService } from '../../providers/multi-user-appointment.service';
import { MultiUserAppointment, MultiUserAppointmentResponse } from './multi-user-appointment.entity';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';
import { delayedRetry } from '../../../utils/http-helper/delayed-retry';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../../providers/store/app.action';
import { FeatureFlagService } from '../../../feature-flag/providers/feature-flag.service';
import { FeatureFlag } from '../../../feature-flag/feature-flag.types';

@Injectable()
export class MultiUserAppointmentEffects {
    constructor(private actions$: Actions,
                private createMultiUserTanService: CreateMultiUserTanService,
                private multiUserAppointmentService: MultiUserAppointmentService,
                private router: RouterHelperService,
                private createMultiUserSuccessHandlerService: CreateMultiUserSuccessHandlerService,
                private viewportScroller: ViewportScroller,
                private featureFlag: FeatureFlagService) {
    }

    fetchMultiUserAppointments$ = createEffect(() => this.actions$.pipe(
        ofType(fetchMultiUserAppointmentsAction),
        exhaustMap(action => {
            return this.multiUserAppointmentService.loadAppointments(action.params)
                .pipe(
                    delayedRetry(),
                    map((response: MultiUserAppointmentResponse) => {
                        const {pagination} = response;
                        const items = response.items.map((appointment: MultiUserAppointment) => MultiUserAppointmentService.addTimeData(appointment));
                        return multiUserAppointmentsFetchSuccessAction({items, pagination});
                    }),
                    takeUntil(this.actions$.pipe(ofType(cancelMultiUserAppointmentsRequest))),
                    catchError((error: HttpErrorResponse) => of(multiUserAppointmentsFetchErrorAction({error})))
                );
        }))
    );

    doRemoveAllAppointments$ = createEffect( () => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        filter(() => this.featureFlag.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL)),
        map(() => removeAllMultiUserAppointmentsAction())
    ));

    scrollTop$ = createEffect(() => this.actions$.pipe(
        ofType(removeAllMultiUserAppointmentsAction),
        tap(action => {
            if (action.topPosition !== null) {
                this.viewportScroller.scrollToPosition([0, action.topPosition]);
            }
        })
    ),
        { dispatch: false });

    doCreateMultiUserTan$ = createEffect(() => this.actions$.pipe(
        ofType(createMultiUserAppointmentAction),
        switchMap((action) => {
            return this.createMultiUserTanService
                .createMultiUserTan(action.payload).pipe(
                    take(1),
                    map((payload: CreateMultiUserTanAppointmentResponse) => createMultiUserAppointmentSuccessAction({payload})),
                    catchError((error: HttpErrorResponse) => of(createMultiUserAppointmentErrorAction({ error })))
                );
        })
    ));

    doRedirectToSuccessPage$ = createEffect(() => this.actions$.pipe(
        ofType(createMultiUserAppointmentSuccessAction),
        tap(action => {
            if (!!action.payload) {
                this.setTanItems(action.payload.items);
                this.router.navigate([CREATE_APPOINTMENT_SUCCESS]);
            }
        })
    ),
        { dispatch: false });

    doRedirectToFailPage$ = createEffect(() => this.actions$.pipe(
        ofType(createMultiUserAppointmentErrorAction),
        tap(action => {
            this.createMultiUserSuccessHandlerService.error = action.error;
            this.router.navigate([CREATE_APPOINTMENT_FAILURE]);
        })
    ),
        { dispatch: false });

    private setTanItems(items: MultiUserTanItemResponse[]): void {
        this.createMultiUserSuccessHandlerService.items.clear();
        for (let i = 0; i <= items.length - 1; i++) {
            const item = {
                tan: items[i].tan.tan,
                userFullName: items[i].profile.fullName
            };
            this.createMultiUserSuccessHandlerService.items.add(item)
        }
    }
}
