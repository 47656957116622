import { InjectionToken } from '@angular/core';
import { FeatureFlagConfig } from '../../modules/feature-flag/feature-flag.types';

export enum AppConfigKey {
    FEATURE_FLAG = 'featureFlagConfig'
}

export interface AppConfig {
    [AppConfigKey.FEATURE_FLAG]: FeatureFlagConfig
}

export type AppConfigObject = FeatureFlagConfig;

export const APP_CONFIG = new InjectionToken<AppConfig>('app-config');
