import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface AppointmentRequest {
    id: number;
    appointmentId?: number;
    tan?: string;
    profileId?: number;
    confirmationComment?: string;
    productTitle: string;
    firstName: string;
    lastName: string;
    salutation: string;
    email: string;
    fullName: string;
    insuranceNumber: string;
    appointmentMessage: string;
    appointmentDuration: number;
    appointmentStartDateTime: string;
    price: number;
    appointmentCreatedAt: string;
    dataProtectionTerm: number;
    dataProcessTerm: number;
    termsAndConditionsPatientTerm: number;
    cancellationTerm: number;
}

export const appointmentRequestAdapter = createEntityAdapter<AppointmentRequest>();

export interface EntityStateAppointmentRequest extends EntityState<AppointmentRequest> {
    ids: number[];
    entities: { [id: number]: AppointmentRequest };
    isInit: boolean | null;
}

const myDefaultAppointmentRequestList: EntityStateAppointmentRequest = {
    isInit: true,
    ids: [],
    entities: {}
};

export const initialAppointmentRequestListState: EntityStateAppointmentRequest = myDefaultAppointmentRequestList;

