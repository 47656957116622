import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { appointmentRequestReducer } from './store/appointment-request.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppointmentRequestEffects } from './store/appointment-request.effect';
import { AppointmentRequestService } from './providers/appointment-request.service';
import { AppointmentRequestComponent } from './components/appointment-request/appointment-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('appointmentRequest', appointmentRequestReducer),
        EffectsModule.forFeature([AppointmentRequestEffects]),
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RouterModule,
        FormsModule
    ],
    providers: [AppointmentRequestService],
    declarations: [AppointmentRequestComponent],
    exports: [AppointmentRequestComponent]
})
export class AppointmentRequestModule {
}
