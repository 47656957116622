import { Action } from '@ngrx/store';
import { ConsultationHour } from '../consultation-hour.model';

export enum ConsultationHourActionTypes {
    LoadConsultationHours = '[ConsultationHour] Load ConsultationHours',
    AddConsultationHour = '[ConsultationHour] Add ConsultationHour',
    AddConsultationHours = '[ConsultationHour] Add ConsultationHours',
    UpdateConsultationHour = '[ConsultationHour] Update ConsultationHour',
    UpdateConsultationHours = '[ConsultationHour] Update ConsultationHours',
    DeleteConsultationHour = '[ConsultationHour] Delete ConsultationHour',
    DeleteConsultationHours = '[ConsultationHour] Delete ConsultationHours',
    ClearConsultationHours = '[ConsultationHour] Clear ConsultationHours'
}

export class LoadConsultationHours implements Action {
    readonly type = ConsultationHourActionTypes.LoadConsultationHours;

    constructor(public payload: { consultationHours: ConsultationHour[] }) {
    }
}

export class AddConsultationHour implements Action {
    readonly type = ConsultationHourActionTypes.AddConsultationHour;

    constructor(public payload: { consultationHour: ConsultationHour }) {
    }
}


export class AddConsultationHours implements Action {
    readonly type = ConsultationHourActionTypes.AddConsultationHours;

    constructor(public payload: { consultationHours: ConsultationHour[] }) {
    }
}

export class UpdateConsultationHour implements Action {
    readonly type = ConsultationHourActionTypes.UpdateConsultationHour;

    constructor(public payload: { consultationHour: { id: number; changes: ConsultationHour } }) {
    }
}

export class UpdateConsultationHours implements Action {
    readonly type = ConsultationHourActionTypes.UpdateConsultationHours;

    constructor(public payload: { consultationHours: { id: number; changes: ConsultationHour }[] }) {
    }
}

export class DeleteConsultationHour implements Action {
    readonly type = ConsultationHourActionTypes.DeleteConsultationHour;

    constructor(public payload: { id: number }) {
    }
}

export class DeleteConsultationHours implements Action {
    readonly type = ConsultationHourActionTypes.DeleteConsultationHours;

    constructor(public payload: { ids: number[] }) {
    }
}

export class ClearConsultationHours implements Action {
    readonly type = ConsultationHourActionTypes.ClearConsultationHours;
}

export type ConsultationHourActions =
    LoadConsultationHours
    | AddConsultationHour
    | AddConsultationHours
    | UpdateConsultationHour
    | UpdateConsultationHours
    | DeleteConsultationHour
    | DeleteConsultationHours
    | ClearConsultationHours;
