<div class="tab-list-header">
    <div class="content-wrapper">
        <div class="tab-list-item active"
             [routerLink]="[upcomingLink]"
             [ngClass]="{'active': isActiveTab(UPCOMING)}">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.UPCOMING' | translate}}</span>
        </div>
        <div class="tab-list-item"
             [routerLink]="[requestLink]"
             [ngClass]="{'active': isActiveTab(REQUESTS)}"
             *ngIf="!hideTabBasedOnInstitution">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.REQUESTS' | translate}}
                <span class="badge"
                      [ngClass]="{'green':(openRequests$ | async) > 0}">{{ (openRequests$ | async) }}</span>
            </span>
        </div>
        <div class="tab-list-item"
             [routerLink]="[historyLink]"
             [ngClass]="{'active': isActiveTab(HISTORY)}">
            <span>{{ 'APPOINTMENT-OVERVIEW.TAB.HISTORY' | translate}}</span>
        </div>
    </div>
</div>
<div class="dashboard-container" *ngIf="(isInit$ | async)">
    <div class="container-fluid">
        <div class="preloader-container">
            <h3 class="preloader-container-message">
                {{ 'APPOINTMENT-OVERVIEW.APPOINTMENTS-LOADING' | translate }}
            </h3>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
