import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray } from '@angular/forms';
import { CustomValidators } from '../../../../../forms/validators/custom-validator';

export class CreateTanAppointmentFormBuilder {
    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            tanDate: ['', [Validators.required, CustomValidators.DateIsTodayOrAfter()]],
            tanBeginning: ['', Validators.compose([Validators.required])],
            tanDuration: [{id: '10', name: '10 min'}, Validators.compose([Validators.required])],
            patients: formBuilder.array([]),
            isEbmCheckBox: [false],
            patientEmailMessage: [null],
            terminDescription: [''],
            message: ['']
        });
    }

    public static createPatient(formBuilder: UntypedFormBuilder, id: string): UntypedFormGroup {
        return formBuilder.group({
            id: [id],
            salutation: ['', Validators.compose([Validators.required])],
            firstName: ['', Validators.compose([Validators.required])],
            lastName: ['', Validators.compose([Validators.required])],
            birthday: formBuilder.group(
                {
                    birthDay: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                    birthMonth: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                    birthYear: ['', Validators.compose([
                        CustomValidators.validDate({day: 'birthDay', month: 'birthMonth', year: 'birthYear'})
                    ])],
                }
            ),
            patientEmail: ['']
        });
    }
}
