import { NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import * as appointmentProduct from './product/store/product.reducer';
import * as consultationHour from './consultation-hour/store/consultation-hour.reducer';
import { ConsultationHourService } from './consultation-hour/providers/consultation-hour.service';
import { ProductService } from './product/providers/product.service';
import { EffectsModule } from '@ngrx/effects';
import { ConsultationHourEffects } from './consultation-hour/store/consultation-hour.effect';
import { ProductEffects } from './product/store/product.effect';

export const reducers: ActionReducerMap<any> = {
    appointmentProduct: appointmentProduct.reducer,
    consultationHour: consultationHour.reducer,
};

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('appointment-type', reducers),
        EffectsModule.forFeature([ConsultationHourEffects]),
        EffectsModule.forFeature([ProductEffects])
    ],
    providers: [ProductService, ConsultationHourService],
    declarations: []
})
export class AppointmentTypeModule {
}
