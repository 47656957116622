import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError as _throw } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
    CreateMultiUserTanAppointmentRequest,
    CreateMultiUserTanAppointmentResponse
} from './create-multi-user-tan.types';

@Injectable({
    providedIn: 'root'
})
export class CreateMultiUserTanService {
    private createMultiUserTanEndpoint: string = environment.createMultiUserTanEndpoint;

    constructor(private http: HttpClient) {
    }

    public createMultiUserTan(dto: CreateMultiUserTanAppointmentRequest): Observable<CreateMultiUserTanAppointmentResponse> {
        return this.http.post<CreateMultiUserTanAppointmentResponse>(this.createMultiUserTanEndpoint, dto)
            .pipe(
                switchMap((result: CreateMultiUserTanAppointmentResponse) => {
                    if (result.hasOwnProperty('items')) {
                        return of(result)
                    } else {
                        return _throw(new Error('Wrong response object by tan conference creation'));
                    }
                })
            );
    }
}
