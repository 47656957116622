import { map, tap } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ConsultationHourService } from '../../../appointment-type/consultation-hour/providers/consultation-hour.service';
import { ConsultationHour } from '../../../appointment-type/consultation-hour/consultation-hour.model';
import { Observable } from 'rxjs';
import { ProductService } from '../../../appointment-type/product/providers/product.service';
import { Product } from '../../../appointment-type/product/product.model';
import { Moment } from 'moment';
import { TimeHelperService } from '../../../../utils/time-helpers/time-helper.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-daily-timeframe',
    templateUrl: './daily-timeframe.component.html',
    styleUrls: ['./daily-timeframe.component.scss']
})
export class DailyTimeframeComponent implements OnInit, OnDestroy {

    @Input() dayOfWeek: number;

    public consultationHourList$: Observable<ConsultationHour[]>;
    public createNewConsultationHour = false;
    public modifyConsultationHour = false;
    public allProducts: Product[];
    private productSubscription: Subscription = null;

    constructor(private chService: ConsultationHourService,
                private productService: ProductService) {
        this.consultationHourList$ = this.chService.getAllConsultationHoursFromStore().pipe(
            map((chList: ConsultationHour[]) => chList.filter(ch => ch.day === this.dayOfWeek)));
    }

    ngOnInit() {
        this.productSubscription = this.productService.getAllProductsFromStore().pipe(tap(list => this.allProducts = list)).subscribe();
    }

    mapMomentToTimeString(moment: Moment): string {
        return moment.local().format('HH:mm');
    }

    findProductById(id): Product {
        return this.allProducts.find(p => p.id.toString() === id.toString());
    }

    public isProductTooLongForTimespan(product: Product, startTime: Moment, endTime: Moment): boolean {
        return (product.duration > TimeHelperService.getDurationInMinutes(startTime, endTime));
    }

    ngOnDestroy(): void {
        if (this.productSubscription !== null) {
            this.productSubscription.unsubscribe();
        }
    }
}
