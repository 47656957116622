<div class="content-box {{status}}" *ngIf="status === 'open'">
    <div class="header-info-line">
        <i class="fa fa-comment"></i> <span>{{ 'APPOINTMENT-REQUEST.REQUEST-RECEIVED' | translate}} </span><span
            class="bold">{{ appointmentRequest.appointmentCreatedAt | date:'dd.LL.yyyy HH:mm' }}</span>
    </div>
    <h2> {{ appointmentRequest.fullName }}</h2>
    <span class="appointment-type">
        {{ appointmentRequest.productTitle }}
    </span>
    <div class="personal-info-holder" *ngIf="showPersonalInfo()">
        <a *ngIf="!!appointmentRequest.email" href="mailto:{{appointmentRequest.email}}">
            <i class="fa fa-envelope"></i> {{appointmentRequest.email}}
        </a>
        <span class="bold" *ngIf="appointmentRequest.insuranceNumber">
            | {{ 'APPOINTMENT-REQUEST.INSURANCE_NUMBER' | translate}}:
        </span>{{appointmentRequest.insuranceNumber}}
        <div class="appointment-message" *ngIf="appointmentRequest.appointmentMessage">
            <span class="bold">
                {{ 'APPOINTMENT-REQUEST.APPOINTMENT_MESSAGE' | translate}}:
            </span>
            {{appointmentRequest.appointmentMessage}}
        </div>
    </div>
    <div class="tagline">
        <div class="tag">{{ appointmentRequest.appointmentStartDateTime | date:'dd.LL.yyyy HH:mm' }}</div>
        <div class="tag">{{ appointmentRequest.price }} €</div>
    </div>
    <div class="decide-space">
        <div class="radiobutton-group-space row">
            <div class="radiobutton-space accept-radiobutton col-sm-6"
                 (click)="acceptOrDecline='accept'">
                <div class="wrapper"
                     [ngClass]="{'active': acceptOrDecline === 'accept'}">
                    <input id="accept"
                           class="radiobutton-item"
                           type="radio"
                           [checked]="acceptOrDecline === 'accept'"
                           [(ngModel)]="acceptOrDecline"
                           [name]="'acceptOrDecline'+randomIdentifier"
                           [value]="'accept'">
                    <label for="accept">{{ 'APPOINTMENT-REQUEST.ACCEPT' | translate}}</label>
                </div>
            </div>
            <div class="radiobutton-space decline-radiobutton col-sm-6"
                 (click)="acceptOrDecline='decline'">
                <div class="wrapper"
                     [ngClass]="{'active': acceptOrDecline === 'decline'}">
                    <input id="decline"
                           class="radiobutton-item"
                           type="radio"
                           [checked]="acceptOrDecline === 'decline'"
                           [(ngModel)]="acceptOrDecline"
                           [name]="'acceptOrDecline'+randomIdentifier"
                           [value]="'decline'">
                    <label for="decline">{{ 'APPOINTMENT-REQUEST.DECLINE' | translate}}</label>
                </div>
            </div>
        </div>
        <div class="confirmation-space"
             *ngIf="acceptOrDecline === 'accept'">
            <form [formGroup]="acceptingForm" role="form">
                <label for="accept-message">{{ 'APPOINTMENT-REQUEST.CONFIRMATION-MESSAGE-NAME' | translate}}</label>
                <textarea name="confirmation-message"
                          formControlName="confirmation_comment"
                          placeholder="{{ 'APPOINTMENT-REQUEST.CONFIRMATION-ACCEPT-TEXTAREA' | translate }}"
                          id="accept-message"
                          rows="2">{{ 'APPOINTMENT-REQUEST.ACCEPT-EXAMPLE-MESSAGE' | translate}}</textarea>
                <p class="acceptance-notice">{{ 'APPOINTMENT-REQUEST.MESSAGE-NOTICE' | translate}}</p>
                <div class="submit-button-space">
                    <button type="button"
                            class="btn btn-submit btn-success"
                            (click)="onAccept(); false">
                        {{ 'APPOINTMENT-REQUEST.ACCEPT-SUBMIT-BTN' | translate}} <i class="fa fa-check-circle"></i>
                    </button>
                </div>
            </form>
        </div>
        <div class="confirmation-space"
             *ngIf="acceptOrDecline === 'decline'">
            <form [formGroup]="rejectionForm" role="form">
                <label for="decline-message">{{ 'APPOINTMENT-REQUEST.CONFIRMATION-MESSAGE-NAME' | translate}}</label>
                <textarea name="confirmation-message"
                          formControlName="confirmation_comment"
                          placeholder="{{ 'APPOINTMENT-REQUEST.CONFIRMATION-DECLINE-TEXTAREA' | translate }}"
                          id="decline-message"
                          rows="2">{{ 'APPOINTMENT-REQUEST.DECLINE-EXAMPLE-MESSAGE' | translate}}</textarea>
                <p class="acceptance-notice">{{ 'APPOINTMENT-REQUEST.MESSAGE-NOTICE' | translate}}</p>
                <div class="submit-button-space">
                    <button type="button"
                            class="btn btn-submit btn-danger"
                            (click)="onDecline(); false">
                        {{ 'APPOINTMENT-REQUEST.DECLINE-SUBMIT-BTN' | translate}} <i class="fa fa-times-circle"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="content-box {{status}}" *ngIf="!(status === 'open')">
    <p class="success-message"
       *ngIf="status === 'accepted'">{{ 'APPOINTMENT-REQUEST.ACCEPT-SUCCESS-MESSAGE' | translate }} <i
            class="fa fa-check"></i></p>
    <p class="success-message"
       *ngIf="status === 'rejected'">{{ 'APPOINTMENT-REQUEST.DECLINE-SUCCESS-MESSAGE' | translate }} <i
            class="fa fa-times"></i></p>
    <p>
        <span class="full-name"> {{ appointmentRequest.fullName }}</span> |
        <span class="appointment-type">{{ appointmentRequest.productTitle }}</span>
    </p>
    <div class="tagline">
        <div class="tag">{{ appointmentRequest.appointmentStartDateTime | date:'dd.LL.yyyy HH:mm' }}</div>
        <div class="tag">{{ appointmentRequest.price }} €</div>
    </div>
    <p class="further-links"
       *ngIf="status === 'accepted'">{{ 'APPOINTMENT-REQUEST.ACCEPT-FURTHER-LINKS-TEXT' | translate }}
        <a [routerLink]="[upcomingLink]">{{'APPOINTMENT-REQUEST.ACCEPT-FURTHER-LINKS-NAME' | translate }}</a></p>
    <p class="further-links"
       *ngIf="status === 'rejected'">{{ 'APPOINTMENT-REQUEST.DECLINE-FURTHER-LINKS-TEXT' | translate }}</p>
</div>
