import { Action, createAction, props } from '@ngrx/store';
import { Conference } from '../../conference/store/conference.entity';

export const WAITINGROOM_INIT = 'WAITINGROOM_INIT';
export const WAITINGROOM_FAIL_ACTION = 'WAITINGROOM_FAIL_ACTION';
export const WAITINGROOM_IS_ONLINE = 'WAITINGROOM_IS_ONLINE';
export const WAITINGROOM_SWITCH_CONFERENCE = 'WAITINGROOM_SWITCH_CONFERENCE';
export const WAITINGROOM_PEAR_READY = 'WAITINGROOM_PEAR_READY';
export const WAITINGROOM_PEAR_NOT_READY = 'WAITINGROOM_PEAR_NOT_READY';
export const WAITINGROOM_START_VIDEO_CONFERENCE = 'WAITINGROOM_START_VIDEO_CONFERENCE';
export const WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY = 'WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY';
export const WAITINGROOM_CLOSE_VIDEO_CONFERENCE = 'WAITINGROOM_CLOSE_VIDEO_CONFERENCE';
export const WAITINGROOM_RTC_DETECTION_MODAL_MANUALLY_CLOSED = 'WAITINGROOM_RTC_DETECTION_MODAL_MANUALLY_CLOSED';

export const WAITINGROOM_START_MUC_VIDEO_CONFERENCE = 'WAITINGROOM_START_MUC_VIDEO_CONFERENCE';
export const WAITINGROOM_START_MUC_VIDEO_CONFERENCE_REMOTELY = 'WAITINGROOM_START_MUC_VIDEO_CONFERENCE_REMOTELY';

export interface WaitingroomAction extends Action {
    payload: Conference | null;
}

export class WaitingroomtInitAction implements WaitingroomAction {
    readonly type = WAITINGROOM_INIT;
    public payload = null;
}

export class WaitingroomIsOnlineAction implements WaitingroomAction {
    readonly type = WAITINGROOM_IS_ONLINE;

    constructor(public payload: Conference) {
    }
}

export class WaitingroomSwitchConferenceAction implements WaitingroomAction {
    readonly type = WAITINGROOM_SWITCH_CONFERENCE;

    constructor(public payload: Conference) {
    }
}

export class WaitingroomPeerReadyAction implements WaitingroomAction {
    readonly type = WAITINGROOM_PEAR_READY;
    public payload = null;
}

export class WaitingroomPeerNotReadyAction implements WaitingroomAction {
    readonly type = WAITINGROOM_PEAR_NOT_READY;
    public payload = null;
}

export class WaitingroomStartVideoConference implements WaitingroomAction {
    readonly type = WAITINGROOM_START_VIDEO_CONFERENCE;

    constructor(public payload: Conference) {
    }
}

export class WaitingroomStartVideoConferenceRemotely implements WaitingroomAction {
    readonly type = WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY;
    public payload = null;
}

export const waitingRoomStartMUCVideoConference = createAction(
    WAITINGROOM_START_MUC_VIDEO_CONFERENCE,
    props<{payload: Conference}>()
);

export const waitingRoomStartMUCVideoConferenceRemotely = createAction(
    WAITINGROOM_START_MUC_VIDEO_CONFERENCE_REMOTELY,
    props<{payload: null}>()
);

export class WaitingroomCloseVideoConference implements WaitingroomAction {
    readonly type = WAITINGROOM_CLOSE_VIDEO_CONFERENCE;
    public payload = null;
}

export class WaitingroomtFailAction implements WaitingroomAction {
    readonly type = WAITINGROOM_FAIL_ACTION;
    public payload = null;

    constructor() {
    }
}

export class WaitingroomRtcDetectionModalManuallyClosedAction implements WaitingroomAction {
    type = WAITINGROOM_RTC_DETECTION_MODAL_MANUALLY_CLOSED;
    payload = null;
}
