import {mapTo} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {APP_CHANGE_TO_ANONYMOUS_STATE} from '../../../../../providers/store/app.action';
import {ClearConsultationHours} from './consultation-hour.actions';


@Injectable()
export class ConsultationHourEffects {
    constructor(private actions$: Actions) {
    }

     doRemoveAllConsultationHours$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new ClearConsultationHours())));
}
