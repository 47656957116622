export enum FeatureFlag {
    MULTI_USER_CALL = 'isMultiUserCall'
}

export interface FeatureFlagConfig {
    [FeatureFlag.MULTI_USER_CALL]: boolean;
}

export interface FeatureFlagSettings {
    redirectWhenFeatureIsOn: boolean,
    requiredFeatureFlag: FeatureFlag,
    redirectRoute: string
}

export function getFeatureFlagRouteSettings(redirectWhenFeatureIsOn: boolean, requiredFeatureFlag: FeatureFlag, redirectRoute: string) {
    return {
        redirectWhenFeatureIsOn,
        requiredFeatureFlag,
        redirectRoute
    }
}
